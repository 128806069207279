import React, { useCallback, useEffect, useState } from 'react'
import { ListItemText, } from '@mui/material'
import { Box, Divider, List, ListItemButton, styled } from '@mui/joy'
import { useNavigate } from 'react-router'
import { useEvents } from '../EventProvider'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { MonitorEvent } from '../event'
import { LoadingIndicator } from '../../_more/app-layout/LoadingIndicator'


export function EventList() {
  const { filteredEvents } = useEvents()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const eventsPerPage = 20
  const [loadedEvents, setLoadedEvents] = useState<MonitorEvent[]>([])
  const [page, setPage] = useState(1)
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.15,
  })

  useEffect(() => {
    loadMoreEvents()
  }, [])

  useEffect(() => {
    if (inView) {
      loadMoreEvents()
    }
  }, [inView])

  const loadMoreEvents = useCallback(() => {
    const nextPage = page + 1
    const newEvents = filteredEvents.slice(0, eventsPerPage * nextPage)
    setLoadedEvents(newEvents)
    setPage(nextPage)
  }, [page, filteredEvents])


  return (
    <>
      <List sx={{ width: '100%' }}>
        {loadedEvents.map((event) => (
          <React.Fragment key={event.id}>
            <ListItemButton onClick={() => navigate(event.id)}>
              <Box display='flex' justifyContent='space-between'
                   alignItems='flex-start' width='100%' flexDirection='column'>

                <ListItemText sx={{ width: '100%' }} secondary={
                  <span style={{ display: 'flex', gap: '4px' }}>
                      <span>{t(event.categoryName)}</span>
                      <span className='spacer'></span>
                      <Danger level={event.level} />
                  </span>
                } />
                <ListItemText sx={{ width: '100%' }} primary={event.title} secondary={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='spacer'></span>
                        {getTimeFormatted(event.time)}
                    </span>}
                />
              </Box>
            </ListItemButton>
            <Divider component='li' />
          </React.Fragment>
        ))}
      </List>
      <Box ref={ref}
           sx={{ display: loadedEvents.length < filteredEvents.length ? 'block' : 'none' }}>
        <LoadingIndicator loading={true} />
      </Box>
    </>
  )
}

function getTimeFormatted(time: string) {
  const date = new Date(time)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  function f(value: number) {
    return value < 10 ? '0' + value : value
  }

  return `${f(day)}.${f(month)}.${year} ${f(hours)}:${f(minutes)}`
}

function Danger({ level }: { level: number }) {
  const key = level - 1
  const dangerOutputs = [
    {
      label: 'Gering',
      color: 'hsl(45,100%,70%)',
    },
    {
      label: 'Moderat',
      color: 'hsl(35,100%,70%)',
    },
    {
      label: 'Hoch',
      color: 'hsl(25,100%,70%)',
    },
    {
      label: 'Schwere',
      color: 'hsl(15,100%,70%)',
    },
    {
      label: 'Extreme',
      color: 'hsl(0,100%,70%)',
    }
  ]

  if (key < 0 || dangerOutputs.length < key) {
    return <></>
  }

  const StyledSpan = styled('span')`
      color: ${dangerOutputs[key].color};
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
      text-align: center;
      background: ${dangerOutputs[key].color.replace(')', ',20%)')};
      border: 2px solid ${dangerOutputs[key].color};
      border-radius: 50%;
  `

  return (
    <StyledSpan>
      {level}
    </StyledSpan>
  )
}
