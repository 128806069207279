import React, { useState } from 'react'
import { ToggleButtonGroup } from '@mui/material'
import i18n, { t } from 'i18next'
import { Typography } from '@mui/joy'
import { HStack, Spacer } from '../../../../lib/components/flex'
import { SmallSwitcher } from './styled-components'

export const LanguageSwitcher: React.FC = () => {
  const [language, setLanguage] = useState(() => localStorage.getItem('language') || i18n.language || 'de')

  const handleChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string | null) => {
    if (newLanguage) {
      setLanguage(newLanguage)
      handleLanguageChange(newLanguage)
    }
  }

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language).then(() => {
      localStorage.setItem('language', language)
      console.log(`Language changed to ${language}`)
      window.location.reload()
    })
  }

  return (
    <HStack sx={{ marginBottom: '0.2rem' }}>
      <Typography sx={{ marginLeft: '0.5rem', marginRight: '0.3rem', width: '5rem' }}>
        {t('language')}
      </Typography>
      <Spacer />
      <ToggleButtonGroup
        color="primary"
        value={language}
        exclusive
        onChange={handleChange}
        aria-label="Language"
        sx={{ height: '25px', marginRight: '0.5rem' }}
      >
        <SmallSwitcher value="de" sx={{ width: '45px', height: '18px' }}>DE</SmallSwitcher>
        <SmallSwitcher value="en" sx={{ width: '45px', height: '18px' }}>EN</SmallSwitcher>
      </ToggleButtonGroup>
    </HStack>
  )
}