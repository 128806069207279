import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ChatContent } from './chat/ChatContent'
import { GlobalstarKeyContent } from './keys/GlobalstarKeyContent'
import { DeviceContent } from './devices/DeviceContent'

interface Props {

}

export function GlobalstarContent({}: Props) {

  return (
    <>
      <Routes>
        <Route index element={<DeviceContent />} />
        <Route
          path='devices/:deviceId'
          element={<ChatContent backUrl='/globalstar' />}
        />
        <Route path='keys' element={<GlobalstarKeyContent />} />
      </Routes>
    </>
  )
}
