import React, { useEffect, useRef } from 'react'
import { List } from '@mui/joy'
import { VStack } from '../../../../../lib/components/flex'
import { DrawerContent } from '../../../_more/app-layout/DrawerContent'
import { useParams } from 'react-router-dom'
import { isNotPresent } from '../../../../../lib/utils/utils'
import { useGlobalstarChat } from './useGlobalstarChat'
import { DayMessageList } from './DayMessageList'
import { SendMessageBar } from './SendMessageBar'
import { scrollDown } from '../../../../../lib/utils/scroll-utils'

interface Props {
  backUrl: string
}

export function ChatContent({ backUrl }: Props) {
  const deviceId = useParams().deviceId!!
  const {
    groupedMessages,
    sendMessage,
    device,
    isSending
  } = useGlobalstarChat(deviceId)

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollDown(wrapperRef.current)
  }, [groupedMessages])

  if (isNotPresent(device)) return <></>

  return (
    <DrawerContent
      title={device.name}
      backUrl={backUrl}
      bottomBar={
        device.isMessageReceiver && (
          <SendMessageBar sendMessage={sendMessage} isSending={isSending} />
        )
      }
    >
      <VStack ref={wrapperRef}>
        <List className='spacer'>
          {
            Object.keys(groupedMessages).sort().map((key) => (
              <DayMessageList key={key} messages={groupedMessages[key]} />
            ))
          }
        </List>
      </VStack>
    </DrawerContent>
  )
}
