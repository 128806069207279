import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { IconButton } from '@mui/material'
import {
  Add,
  VisibilityOffOutlined,
  VisibilityOutlined
} from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { useReports } from '../ReportProvider'
import { ReportEditor } from './ReportEditor'
import { ReportList } from './ReportList'

interface Props {

}

export function ReportContent({}: Props) {
  const { showInMap, setShowInMap } = useReports()

  const navigate = useNavigate()

  return (
    <Routes>
      <Route index element={
        <DrawerContent
          title='Reporte'
          backUrl='/'
          actions={
            <>
              <IconButton onClick={() => navigate('add')}>
                <Add />
              </IconButton>

              <IconButton onClick={() => setShowInMap(!showInMap)}>
                {showInMap ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
              </IconButton>
            </>
          }
        >
          <ReportList />
        </DrawerContent>
      } />

      <Route path='add' element={<ReportEditor />} />
      <Route path='update/:reportId' element={<ReportEditor />} />
    </Routes>
  )
}
