import React from 'react'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { FlightFilter } from './FlightFilter'
import { FlightList } from './FlightList'
import { Route, Routes } from 'react-router-dom'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { FlightDetails } from './FlightDetails'
import { IconButton } from '@mui/joy'
import { useFlight } from '../FlightRadarProvider'
import { t } from 'i18next'

interface Props {

}

export function FlightContent({}: Props) {
  const { showInMap, setShowInMap } = useFlight()

  return (

    <Routes>
      <Route index element={
        <DrawerContent
          title={t('flights')}
          backUrl='/'
          actions={
            <IconButton onClick={() => setShowInMap(!showInMap)}>
              {showInMap ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          }
        >
          {/*<FlightFilter />*/}
          <FlightList />
        </DrawerContent>
      } />

      <Route path=':flightHex' element={<FlightDetails />} />
    </Routes>

  )
}
