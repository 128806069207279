import { ListItemContent, ListSubheader, Typography } from '@mui/joy'
import React from 'react'
import { GlobalStarChatMessage } from '../../globalstar-model'
import { Multiline } from '../../../_more/components/base-components'
import { MessageWrapperDayMessageList, MessageListItemDayMessageList } from '../../../_more/app-layout/styled-components'

export function DayMessageList({ messages }: {
  messages: GlobalStarChatMessage[]
}) {
  return (
    <>
      <ListSubheader sticky sx={{ justifyContent: 'center' }}>
        {messages[0].sendTime.format('DD.MM.YYYY')}
      </ListSubheader>

      {
        messages.map(message => (

          <MessageWrapperDayMessageList key={message.id} className={message.origin}>
            <MessageListItemDayMessageList
              key={message.id}
              variant='soft'
              className={message.origin}
            >

              <ListItemContent>
                <Typography>
                  <Multiline text={message.text} />
                </Typography>
                <Typography level='body-xs' textAlign='end'>
                  {message.sendTime.format('HH:mm')}
                </Typography>

              </ListItemContent>
            </MessageListItemDayMessageList>
          </MessageWrapperDayMessageList>
        ))
      }
    </>
  )
}