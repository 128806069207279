import React, { createContext, useContext, useState } from 'react'
import { chill } from '../../../../lib/utils/utils'
import { ChildrenProps } from '../../../../lib/types'

const SoftwareKeyboardContext = createContext<
  [boolean, (value: boolean) => void]
>([false, chill])

export function SoftwareKeyboardProvider({ children }: ChildrenProps) {
  const state = useState(false)
  console.log(state[0])

  return (
    <SoftwareKeyboardContext.Provider value={state}>
      {children}
    </SoftwareKeyboardContext.Provider>
  )
}

export function useAssumedSoftwareKeyboardState() {
  return useContext(SoftwareKeyboardContext)
}
