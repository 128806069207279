import { useAuth } from '../../../../../app/auth'
import { useEffect, useState } from 'react'
import { GlobalstarKey } from '../../globalstar-model'
import { serverBaseUrl } from '../../../../../app/base'

export function useGlobalstarKeys() {
  const { authHttp } = useAuth()
  const [keys, setKeys] = useState<GlobalstarKey[]>([])
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  async function loadData() {
    const { data } = await authHttp.get<GlobalstarKey[]>(`${serverBaseUrl}/globalstar/keys`)
    setKeys(data)
  }

  async function addKey(name: string, value: string) {
    if (name.length === 0 || value.length === 0) return

    setIsCreating(true)
    await authHttp.post<GlobalstarKey[]>(`${serverBaseUrl}/globalstar/keys`, {
      name,
      value
    })

    await loadData()
    setIsCreating(false)
  }

  async function deleteKey(keyId: string) {
    await authHttp.delete<GlobalstarKey[]>(`${serverBaseUrl}/globalstar/keys/${keyId}`)
    loadData()
  }

  return {
    keys,
    isCreating,
    addKey,
    deleteKey
  }
}
