import React, { FormEvent, useState } from 'react'
import { Box, Button, Input, Modal, Sheet, styled } from '@mui/joy'
import { useAuth } from '../../app/auth'
import { useNavigate } from 'react-router'
import { VStack } from '../../lib/components/flex'

interface Props {

}

export function SimpleLoginPage({}: Props) {

  const navigate = useNavigate()
  const { requestLoginPasswordOnly } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  async function handleLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      await requestLoginPasswordOnly(email, password)
      navigate('/', { replace: true })
    } catch (e) {
      alert('Wrong Credentials!')
    }
  }

  return (
    <Wrapper>
      <Modal open={true}
             sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center'
             }}
      >
        <Sheet variant='outlined' sx={{outline: 'none'}}>


          <Box padding={6}>
            <form onSubmit={handleLogin}>
              <VStack gap={1}>
                <img src='/res/images/qntrol-logo-white.svg' alt=''
                     height={50} />
                <Input placeholder='Email'
                       value={email}
                       onChange={e => setEmail(e.target.value)}
                />
                <Input placeholder='Password'
                       type='password'
                       value={password}
                       onChange={e => setPassword(e.target.value)}
                />
                <Button type='submit'>Login</Button>
              </VStack>

            </form>

          </Box>

        </Sheet>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
    width: 100vw;
    height: 100vh;
    background-color: black;
    background-image: url("/res/images/gsg9-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`
