import React, { useState } from 'react'
import { Avatar, Badge, Box, Button, DialogActions, DialogTitle, Dropdown, IconButton, ListDivider, Menu, MenuButton, Modal, ModalDialog, Typography } from '@mui/joy'
import { LogoutOutlined } from '@mui/icons-material'
import { isPresent } from '../../../../lib/utils/utils'
import { useUserInfo } from '../../../user/useUserInfo'
import { useAuth } from '../../../../app/auth'
import { t } from 'i18next'
import { LanguageSwitcher } from './LanguageSwitcher'
import { Spacer } from '../../../../lib/components/flex'
import { NavigationSwitcher } from './NavigationSwitcher'

interface UserInfoProps {
  location: string
  onSwitchView: () => void
}

export function UserInfo({ location, onSwitchView }: UserInfoProps) {

  const userInfo = useUserInfo()
  const { logout } = useAuth()
  const [open, setOpen] = useState<boolean>(false)

  const organizationInitials = userInfo?.organizationName?.substring(0, 2).toUpperCase() || '??'

  const menuPlacement = location === 'side' ? 'right' : 'bottom';

  return (
    <Box>
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
          sx={{ width: '30px', height: '30px', marginRight: '0.1rem' }}
        >
          <Badge size='sm' badgeInset="14%" color="success"><Avatar color="neutral" size='sm'>{organizationInitials}</Avatar></Badge>
        </MenuButton>
        <Menu placement={menuPlacement} sx={{ zIndex: 9999, width: '15%' }} variant='soft'>
          {isPresent(userInfo) && (
            <>
              <Typography level="title-lg" sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>{userInfo.organizationName}</Typography>
              <Typography level="body-sm" sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>{userInfo.email}</Typography>
              <ListDivider sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} />
              <LanguageSwitcher/>
              <NavigationSwitcher  location={location} onSwitchView={onSwitchView}/>
              <ListDivider/>
            </>
          )}
          <Button variant="outlined" onClick={() => setOpen(true)} sx={{ marginRight: '0.5rem', marginLeft: '0.5rem' }} color="danger">
            {t('logout')}
            <Spacer />
            <LogoutOutlined />
          </Button>
        </Menu>
      </Dropdown>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <LogoutOutlined />
            {t('areYouSure')}
          </DialogTitle>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={logout}>
              {t('logout')}
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              {t('cancel')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

    </Box>
  )
}
