import React, { ReactNode, useEffect } from 'react'
import { Box, Divider, IconButton, styled, Typography } from '@mui/joy'
import { HStack } from '../../../../lib/components/flex'
import { ArrowBackIosNew } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { isPresent } from '../../../../lib/utils/utils'
import { useAdvancedStyles } from './advanced-styles'
import { useBottomBar } from './BottomBarProvider'
import { useAssumedSoftwareKeyboardState } from './SoftwareKeyboardProvider'
import classNames from 'classnames'
import { WrapperDrawerContent } from './styled-components'

interface Props {
  title: string
  backUrl?: string
  actions?: ReactNode
  bottomBar?: ReactNode
  children: ReactNode
}

export function DrawerContent({
                                title,
                                backUrl,
                                actions,
                                bottomBar,
                                children
                              }: Props) {
  const navigate = useNavigate()

  const styles = useAdvancedStyles()
  const [_, setShowBottomBar] = useBottomBar()
  const [isSoftwareKeyboardActive] = useAssumedSoftwareKeyboardState()

  useEffect(() => {
    if (isPresent(bottomBar)) {
      setShowBottomBar(false)
    }

    return () => {
      setShowBottomBar(true)
    }
  }, [])


  return (
    <WrapperDrawerContent
      className={classNames({ withSafeBottom: !isSoftwareKeyboardActive })}
      sx={{ paddingLeft: '5px', paddingRight: '5px' }}
    >
      <HStack>
        <Box className='spacer'>
          {isPresent(backUrl) && (
            <IconButton size='sm' onClick={() => navigate(backUrl, {replace: true})}>
              <ArrowBackIosNew />
            </IconButton>
          )}
        </Box>
        <Typography level='title-lg'>{title}</Typography>
        <HStack className='spacer' justifyContent='end'>
          {isPresent(actions) && actions}
        </HStack>
      </HStack>

      <Divider />

      <Box className='spacer content-scroller'>
        <Box minHeight='100%' overflow='hidden'>
          {children}
        </Box>
      </Box>

      <Box>
        {
          isPresent(bottomBar)
            ? bottomBar
            : styles['as-is-in-small-mode'] === 'true'
            && <Box height={styles['as-bottom-bar-height']} />
        }
      </Box>

    </WrapperDrawerContent>
  )
}