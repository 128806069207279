import { Box, styled } from '@mui/joy'

const smallModeWidth = 600
const topBarHeight = 45
const bottomBarHeight = 54

export interface AdvancedStyles {
  'as-top-bar-height': number
  'as-bottom-bar-height': number
  'as-small-mode-width': number
  'as-is-in-small-mode': 'true' | 'false'
  'as-main-variant': 'plain' | 'outlined'
  'as-z-indices': {
    logoBar: number;
    drawer: number;
    topBar: number;
    bottomBar: number;
    drawerContentSmallWithBottomBar: number;
  }
}

export function useAdvancedStyles(): AdvancedStyles {
  const isInSmallMode =
    window.matchMedia(`(max-width: ${smallModeWidth}px)`).matches
      ? 'true' : 'false'

  const mainVariant = isInSmallMode ? 'plain' : 'outlined'

  const zIndices = {
    logoBar: 1000,
    drawer: 1001,
    topBar: 1002,
    bottomBar: 1003,
    drawerContentSmallWithBottomBar: 1004
  }

  return {
    'as-top-bar-height': topBarHeight,
    'as-bottom-bar-height': bottomBarHeight,
    'as-small-mode-width': smallModeWidth,
    'as-is-in-small-mode': isInSmallMode,
    'as-main-variant': mainVariant,
    'as-z-indices': zIndices,
  }
}

export const DisplayNoneInSmallMode = styled(Box)`
    @media screen and (max-width: ${smallModeWidth}px) {
        & {
            display: none;
        }
    }
`

export const DisplayBlockInSmallMode = styled(Box)`
    @media screen and (max-width: ${smallModeWidth}px) {
        & {
            display: block;
        }
    }
`
