import React from 'react'
import { Divider, List, ListItemButton, ListItemText } from '@mui/material'
import { useReports } from '../ReportProvider'
import { useNavigate } from 'react-router'

interface Props {

}

export function ReportList({}: Props) {
  const { reports } = useReports()

  const navigate = useNavigate()

  return (
    <>
      <List sx={{ width: '100%' }}>
        {
          reports.map(report => (
            <React.Fragment key={report.id!!}>
              <ListItemButton onClick={() => navigate(`update/${report.id!!}`)}>
                <ListItemText primary={report.title}
                              secondary={report.description.substring(0, 100)} />
              </ListItemButton>
              <Divider component='li' />
            </React.Fragment>
          ))
        }
      </List>
    </>
  )
}
