import { Divider, List, ListSubheader } from '@mui/joy'
import React from 'react'
import { useVessel } from '../MaritimeProvider'
import { Autocomplete, TextField } from '@mui/material'

interface ShipTypeOption {
  label: string
  value: string
}

export function VesselFilter() {
  const {
    setTypeFilter,
  } = useVessel()


  /*
  const shipFlag = [
    { label: 'DE', flag: 'Germany' },
    { label: 'EN', flag: 'England' },
    { label: 'US', flag: 'United States of America' },
    { label: 'FR', flag: 'France' },
    { label: 'BG', flag: 'Belgium' },
  ]*/

  const shipType: ShipTypeOption[] = [
    { label: 'All', value: 'all' },
    { label: 'Passenger', value: '6' },
    { label: 'Cargo', value: '7' },
    { label: 'Tanker', value: '8' },
  ]

  const handleTypeFilterChange = (
    event: React.ChangeEvent<{}>,
    value: ShipTypeOption[]
  ) => {
    if (value.some((v) => v.value === 'all')) {
      setTypeFilter([])
    } else {
      setTypeFilter(value.map((v) => v.value))
    }
  }

  return (
    <List>
      <ListSubheader sticky>Filter</ListSubheader>

      {/*
      <Autocomplete
        multiple
        options={shipFlag}
        disableCloseOnSelect
        limitTags={2}
        size="small"
        renderInput={(params) => <TextField {...params} label="Flag" />}
        sx={{ marginLeft: '5%', marginRight: '5%' }}
      />
      <Box sx={{ height: '10px' }} />
      */}
      <Autocomplete
        multiple
        options={shipType}
        getOptionLabel={(option) => option.label}
        disableCloseOnSelect
        limitTags={1}
        size="small"
        onChange={handleTypeFilterChange}
        renderInput={(params) => <TextField {...params} label="Type" />}
        sx={{ marginLeft: '5%', marginRight: '5%' }}
      />

      <Divider sx={{ marginTop: '10px' }} />


      {/* ETA Filter | Not in working use yet!
      <HStack>
        <ListSubheader sticky>ETA (Ankunftszeit)</ListSubheader>
        <Switch
          checked={etaFilterEnabled}
          onChange={() => setEtaFilterEnabled(!etaFilterEnabled)}
        />
      </HStack>


      <ListItem>
        <HStack>
          <Typography className="spacer">von</Typography>
          <LocalizationProvider
            localeText={germanLocale}
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
          >
            <DateTimePicker
              closeOnSelect={false}
              value={dayjs.unix(fromUnixTime)}
              onChange={(value) => setFromUnixTime(getUnixTime(value))}
            />
          </LocalizationProvider>
        </HStack>
      </ListItem>

      <ListItem>
        <HStack>
          <Typography className="spacer">bis</Typography>
          <LocalizationProvider
            localeText={germanLocale}
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
          >
            <DateTimePicker
              closeOnSelect={false}
              value={dayjs.unix(toUnixTime)}
              onChange={(value) => setToUnixTime(getUnixTime(value))}
            />
          </LocalizationProvider>
        </HStack>
      </ListItem>

      <Divider sx={{ marginTop: '10px' }} />
      */}
    </List>
  )
}
