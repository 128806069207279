import { FilterSection, mergeFilter } from '../../_more/filter/filter-utils'

export type EventCategory = {
  name: string,
  key: string,
  title: string,
  code: string
}

const sections: FilterSection[] = [
  {
    title: 'Extra',
    categories: [
      { name: 'allCategories', key: 'ALL', code: 'all' },
      { name: 'nonCategorized', key: 'NC', code: 'empty' },
    ],
  },
  {
    title: 'fire',
    categories: [
      { name: 'urbanFire', key: 'UF', code: 'urbanfire' },
      { name: 'wildFire', key: 'WF', code: 'wildfire' },
    ],
  },
  {
    title: 'safety',
    categories: [
      { name: 'crime', key: 'CR', code: 'crime' },
      { name: 'demonstrationRiot', key: 'DE', code: 'demonstrationriot' },
      { name: 'terrorism', key: 'TER', code: 'terrorism' },
      { name: 'strike', key: 'SR', code: 'strike' },
      { name: 'politicalCrisis', key: 'PC', code: 'politicalcrisis' },
      { name: 'armedConfliceWar', key: 'WA', code: 'armedconflicewar' },
      { name: 'importantDate', key: 'ID', code: 'importantdate' },
    ],
  },
  {
    title: 'health',
    categories: [
      { name: 'poisoning', key: 'POI', code: 'poisoning' },
      { name: 'epidemic', key: 'EPI', code: 'epidemic' },
      { name: 'personalInjury', key: 'PIN', code: 'personalinjury' },
      { name: 'pestOutbreak', key: 'AP', code: 'pestoutbreak' },
    ],
  },
  {
    title: 'transport',
    categories: [
      { name: 'transportIncident', key: 'TRI', code: 'transportincident' },
      { name: 'transportAccident', key: 'TRA', code: 'transportaccident' },
    ],
  },
  {
    title: 'weather',
    categories: [
      { name: 'severeWeather', key: 'SW', code: 'severeweather' },
      { name: 'flood', key: 'FL', code: 'flood' },
      { name: 'flashFlood', key: 'FFL', code: 'flashflood' },
      { name: 'drought', key: 'DR', code: 'drought' },
      { name: 'heatWave', key: 'HW', code: 'heatwave' },
    ],
  },
  {
    title: 'technology',
    categories: [
      { name: 'powerOutage', key: 'PW', code: 'poweroutage' },
      {
        name: 'technicalAccidentDisaster',
        key: 'TA',
        code: 'technicalaccidentdisaster'
      },
    ],
  },
  {
    title: 'environment',
    categories: [
      { name: 'volcanoActivity', key: 'VOA', code: 'volcanoactivity' },
      { name: 'landSlide', key: 'LS', code: 'landslide' },
    ],
  },
]

export const eventCategories: EventCategory[] = mergeFilter(sections)

