import React from 'react'
import { createContext, useContext, useState } from 'react'
import { chill } from '../../../../lib/utils/utils'
import { ChildrenProps } from '../../../../lib/types'

const BottomBarContext = createContext<
  [boolean, (value: boolean) => void]
>([true, chill])

export function BottomBarProvider({ children }: ChildrenProps) {
  const state = useState(true)

  return (
    <BottomBarContext.Provider value={state}>
      {children}
    </BottomBarContext.Provider>
  )
}

export function useBottomBar() {
  return useContext(BottomBarContext)
}
