import React from 'react'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListSubheader,
  Switch,
  Typography
} from '@mui/joy'
import { useNavigate } from 'react-router'
import { useGlobalstar } from '../../GlobalstarProvider'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/de'
import { deDE } from '@mui/x-date-pickers/locales'
import { HStack } from '../../../../../lib/components/flex'
import { DateTimePicker } from '../../../_more/components/base-components'
import { DeviceDetails } from './DeviceDetails'
import { t } from 'i18next'


export function DeviceList() {
  const {
    fromUnixTime,
    toUnixTime,
    setFromUnixTime,
    setToUnixTime,
    globalstarDevices,
    hiddenMessengerIds,
    toggleHiddenMessengerId,
    showInMap,
    setShowInMap
  } = useGlobalstar()

  const germanLocale =
    deDE.components.MuiLocalizationProvider.defaultProps.localeText

  const navigate = useNavigate()

  function getUnixTime(value: Dayjs | null) {
    return (value ?? dayjs()).unix()
  }

  return (
    <List>
      <ListSubheader sticky>{t('view')}</ListSubheader>

      <Divider />

      <ListItem endAction={
        <Switch
          checked={showInMap}
          onChange={() => setShowInMap(!showInMap)}
        />
      }>
        <ListItemContent onClick={() => setShowInMap(!showInMap)}>
          {t('showInMap')}
        </ListItemContent>
      </ListItem>

      <Divider />

      <ListItem>
        <HStack>
          <Typography className='spacer'>{t('from')}</Typography>
          <LocalizationProvider
            localeText={germanLocale} adapterLocale='de'
            dateAdapter={AdapterDayjs}
          >
            <DateTimePicker
              closeOnSelect={false}
              value={dayjs.unix(fromUnixTime)}
              onChange={value => setFromUnixTime(getUnixTime(value))}
            />
          </LocalizationProvider>
        </HStack>
      </ListItem>

      <Divider />

      <ListItem>

        <HStack>
          <Typography className='spacer'>{t('to')}</Typography>

          <LocalizationProvider
            localeText={germanLocale} adapterLocale='de'
            dateAdapter={AdapterDayjs}
          >
            <DateTimePicker
              closeOnSelect={false}
              value={dayjs.unix(toUnixTime)}
              onChange={value => setToUnixTime(getUnixTime(value))}
            />
          </LocalizationProvider>
        </HStack>
      </ListItem>

      <Divider />


      <ListSubheader sticky>{t('devices')}</ListSubheader>


      <Divider />

      {
        globalstarDevices.map(device => (
          <React.Fragment key={device.id}>
            <ListItem
              sx={{ alignItems: 'start' }}
              endAction={

                <>
                  <Switch
                    checked={!hiddenMessengerIds.includes(device.id)}
                    onChange={() => toggleHiddenMessengerId(device.id)}
                  />
                </>

              }
            >
              <ListItemButton
                onClick={() => navigate(`devices/${device.id}`)}>
                <ListItemContent>
                  <HStack>
                    <Typography className='spacer'>{device.name}</Typography>
                    <ColorBubble color={device.color} />
                  </HStack>
                  <DeviceDetails device={device}></DeviceDetails>
                </ListItemContent>

              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>

        ))
      }

    </List>
  )
}

function ColorBubble({ color }: { color: number[] }) {
  return (
    <Box
      width={12}
      height={12}
      borderRadius='50%'
      bgcolor={`rgb(${color.join(',')})`}
    />
  )
}
