// @ts-ignore
import marinePassengerIcon from '../icons/marine_passenger.png'
// @ts-ignore
import marineCargoIcon from '../icons/marine_cargo.png'
// @ts-ignore
import marineTankerIcon from '../icons/marine_tanker.png'
// @ts-ignore
import marineDefaultIcon from '../icons/marine_default.png'
import mapboxgl from 'mapbox-gl'

const loadImages = (map: mapboxgl.Map, callback: () => void) => {
  const images = [
    { name: 'icon-passenger', url: marinePassengerIcon },
    { name: 'icon-cargo', url: marineCargoIcon },
    { name: 'icon-tanker', url: marineTankerIcon },
    { name: 'icon-default', url: marineDefaultIcon }
  ]

  let imagesLoaded = 0

  images.forEach(image => {
    map.loadImage(image.url, (error, img) => {
      if (error) {
        console.error(`Error loading image ${image.url}:`, error)
        return
      }
      map.addImage(image.name, img!)
      imagesLoaded++
      if (imagesLoaded === images.length) {
        callback()
      }
    })
  })
}

export { loadImages }