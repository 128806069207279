import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { EventContent } from './events/components/EventContent'
import { GlobalstarContent } from './globalstar/modules/GlobalstarContent'
import { ReportContent } from './reports/components/ReportContent'
import { Drawer } from './_more/app-layout/Drawer'
import { TopBar } from './_more/app-layout/TopBar'
import { BottomBar } from './_more/app-layout/BottomBar'
import { LogoBar } from './_more/app-layout/LogoBar'
import { MonitorProviders } from './MonitorProviders'
import { VesselContent } from './maritime/components/VesselContent'

import MonitorMap from './_more/components/MonitorMap'
import { SideBar } from './_more/app-layout/SideBar'
import { StyledButton } from './_more/app-layout/StyledButton'
import { useAdvancedStyles } from './_more/app-layout/advanced-styles'
import { t } from 'i18next'
import { FlightContent } from './flight-radar/components/FlightContent'

export function MonitorPage() {
    const location = useLocation()

    const [showTopBar, setShowTopBar] = useState(() => {
        const savedState = localStorage.getItem('showTopBar')
        return savedState !== null ? JSON.parse(savedState) : true
    })

    const toggleView = () => {
        setShowTopBar((prev: any) => !prev)
    }

    useEffect(() => {
        localStorage.setItem('showTopBar', JSON.stringify(showTopBar))
    }, [showTopBar])

    const advancedStyles = useAdvancedStyles()

    const navigationButtons = (
        <>
            <StyledButton
                to="/globalstar"
                variant="plain"
                color="neutral"
            >
                {t('globalstar')}
            </StyledButton>
            <StyledButton
                to="/flight-radar"
                variant="plain"
                color="neutral"
            >
                {t('flight-radar')}
            </StyledButton>
            <StyledButton
                disabled={true}

                to="/weather"
                variant="plain"
                color="neutral"
            >
                {t('weather')}
            </StyledButton>
            <StyledButton
                disabled={true}

                to="/maritim"
                variant="plain"
                color="neutral"
            >
                {t('maritime')}
            </StyledButton>
            <StyledButton
                disabled={true}

                to="/events"
                variant="plain"
                color="neutral"
            >
                {t('events')}
            </StyledButton>
            <StyledButton
                disabled={true}
                to="/reports"
                variant="plain"
                color="neutral"
            >
                {t('reports')}
            </StyledButton>





        </>
    )

    return (
        <MonitorProviders>
            <LogoBar />
            <BottomBar />
            {showTopBar ? (
                <TopBar onSwitchView={toggleView}>{navigationButtons}</TopBar>
            ) : (
                <SideBar onSwitchView={toggleView}>{navigationButtons}</SideBar>
            )}

            <Drawer
                width={300}
                open={location.pathname.length > 1}
                left={showTopBar ? 0 : advancedStyles['as-top-bar-height']}
                top={showTopBar ? advancedStyles['as-top-bar-height'] : 0}
            >
                <Routes>
                    <Route path="/reports/*" element={<ReportContent />} />
                    <Route path="/events/*" element={<EventContent />} />
                    <Route path="/globalstar/*" element={<GlobalstarContent />} />
                    <Route path="/maritim/*" element={<VesselContent />} />
                    <Route path="/flight-radar/*" element={<FlightContent />} />
                </Routes>
            </Drawer>

            <MonitorMap />
        </MonitorProviders>
    )
}
