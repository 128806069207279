import React from 'react';
import { Box, CircularProgress } from '@mui/joy';

interface LoadingIndicatorProps {
  loading: boolean;
}

export function LoadingIndicator({ loading }: LoadingIndicatorProps) {
  return (
    <Box sx={{ display: loading ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', width: '100%', padding: 2, }}>
      <CircularProgress />
    </Box>
  );
}
