import React, { useState, useEffect, useCallback } from 'react'
import { Divider, List, ListItemButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, styled } from '@mui/joy'
import EastIcon from '@mui/icons-material/East'
import { format } from 'date-fns'
import { useFlight } from '../FlightRadarProvider'
import { useInView } from 'react-intersection-observer'
import { Flight } from '../flight-radar-model'
import { LoadingIndicator } from '../../_more/app-layout/LoadingIndicator'
import { HStack, VStack } from '../../../../lib/components/flex'
import { isPresent } from '../../../../lib/utils/utils'

export function FlightList() {
  const { flightData } = useFlight()
  const navigate = useNavigate()
  const flightsPerPage = 20
  const [loadedFlights, setLoadedFlights] = useState<Flight[]>([])
  const [page, setPage] = useState(0) // Start from 0 for correct pagination
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.15,
  })

  useEffect(() => {
    loadMoreFlights()
  }, [])

  useEffect(() => {
    if (inView) {
      loadMoreFlights()
    }
  }, [inView])

  const loadMoreFlights = useCallback(() => {
    if (page * flightsPerPage >= flightData.length) return

    const nextPage = page + 1
    const newFlights = flightData.slice(0, flightsPerPage * nextPage)
    setLoadedFlights(newFlights)
    setPage(nextPage)
  }, [page, flightData])

  const formatETA = (eta: string | number | Date | null) =>
    eta ? format(new Date(eta), 'dd.MM HH:mm') : ''

  const getCallSign = (callSign: Flight) =>
    `${callSign.hex.toUpperCase()} (ID)`;

  const getDestination = (destination: string) =>
    destination === '' ? 'UNKNOWN' : destination

  return (
    <>
      <List sx={{ width: '100%' }}>
        {loadedFlights.map((flight) => (
          <React.Fragment key={flight.hex}>
            <ListItemButton onClick={() => navigate(`/flight-radar/${flight.hex}`)}>
              <VStack>
                <HStack sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>{getCallSign(flight)}</Typography>
                </HStack>
                <HStack sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography level="body-xs" sx={{ width: '45%' }}>""</Typography>
                  <EastIcon sx={{ width: '10%', fontSize: '0.7rem' }} />
                  <VStack sx={{ display: 'flex', alignItems: 'end', width: '45%' }}>
                    <Typography level="body-xs">Altitude</Typography>
                    <PortTypography>{flight.alt_geom ? flight.alt_geom.toString() : ""}</PortTypography>
                  </VStack>
                </HStack>
              </VStack>
            </ListItemButton>
            <Divider component="li" />
          </React.Fragment>
        ))}
      </List>
      <Box ref={ref} sx={{ display: loadedFlights.length < flightData.length ? 'block' : 'none' }}>
        <LoadingIndicator loading={true} />
      </Box>
    </>
  )
}

const PortTypography = styled(Typography)`
    width: 45%;
    max-width: 45%;
    white-space: nowrap;
    font-size: 0.7rem;
    text-align: center;
`
