import React from 'react'
import { FlightOutlined, PublicOutlined, ReportProblemOutlined, SummarizeOutlined, ThunderstormOutlined, DirectionsBoatOutlined } from '@mui/icons-material'
import { Divider, Typography } from '@mui/joy'
import { HStack } from '../../../../lib/components/flex'
import { useNavigate } from 'react-router'
import classNames from 'classnames'
import { useAdvancedStyles } from './advanced-styles'
import { useBottomBar } from './BottomBarProvider'
import { WrapperBottomBar, ItemButtonBottomBar } from './styled-components'
import { t } from 'i18next'

interface Props {

}

export interface BottomNavigationBarItem {
  label: string
  icon: React.ReactNode
  url: string
  disabled?: boolean
}

export function BottomBar({}: Props) {

  const styles = useAdvancedStyles()

  const items: BottomNavigationBarItem[] = [
    /*{
      label: t('reports'),
      icon: <ReportProblemOutlined />,
      url: '/report',
      disabled: true
    },*/
    {
      label: t('events'),
      icon: <SummarizeOutlined />,
      url: '/events',
      disabled: true
    },
    {
      label: t('globalstar'),
      icon: <PublicOutlined />,
      url: '/globalstar',
    },
    {
      label: t('maritime'),
      icon: <DirectionsBoatOutlined />,
      url: '/maritim',
      disabled: true
    },
    {
      label: t('flights'),
      icon: <FlightOutlined />,
      url: '/flight-radar',
    },
    {
      label: t('weather'),
      icon: <ThunderstormOutlined />,
      url: '/weather',
      disabled: true
    }
  ]

  const [showBottomBar] = useBottomBar()

  const navigate = useNavigate()

  function getClassNames(item: BottomNavigationBarItem) {
    return classNames({
      active: window.location.pathname.startsWith(item.url),
    })
  }

  const handleClick = (url: string) => () => { // Modify this line to wrap handleClick in an anonymous function
    if (window.location.pathname === url) {
      navigate('/', { replace: true })
    } else {
      navigate(url, { replace: true })
    }
  }

  if (!showBottomBar) return <></>

  return (
    <WrapperBottomBar {...styles}>
      <Divider />
      <HStack>
        {items.map(item => (
          <ItemButtonBottomBar
            key={item.label}
            variant='plain'
            color='neutral'
            onClick={handleClick(item.url)}
            className={getClassNames(item)}
            disabled={item.disabled}
          >
            {item.icon}
            <Typography level='body-xs'>{item.label}</Typography>
          </ItemButtonBottomBar>

        ))}
      </HStack>
    </WrapperBottomBar>
  )
}
