import React from 'react'
import { eventCategories, EventCategory } from './event-categories'
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useEvents } from '../EventProvider'
import { t } from 'i18next'


export function EventFilter() {
  const { t } = useTranslation()

  const {
    categoriesFilter,
    setCategoriesFilter,
    sourceFilter,
    setSourceFilter
  } = useEvents()

  function handleFilter(values: EventCategory[]) {
    setCategoriesFilter(values)
  }

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Autocomplete
        size='small'
        multiple
        disableCloseOnSelect
        onChange={(_, values) => handleFilter(values)}
        clearIcon={null}
        options={eventCategories}
        groupBy={(option) => t(option.title)}
        getOptionLabel={(option) => t(option.name)}
        getOptionKey={(option) => option.code}
        value={categoriesFilter}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize='small' />}
              checkedIcon={<CheckBox fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected} />
            {t(option.name)}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={t('categories')} />
        )}
      />

      <Autocomplete
        size='small'
        multiple
        disableCloseOnSelect
        onChange={(_, values) => setSourceFilter(values)}
        clearIcon={null}
        options={['A3M', 'NINA']}
        getOptionLabel={(option) => option}
        getOptionKey={(option) => option}
        value={sourceFilter}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize='small' />}
              checkedIcon={<CheckBox fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected} />
            {t(option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={t('source')} />
        )}
      />
    </Box>
  )
}
