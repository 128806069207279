import React, { useRef, useState } from 'react'
import { Divider, IconButton, Textarea } from '@mui/joy'
import { HStack } from '../../../../../lib/components/flex'
import { SendOutlined } from '@mui/icons-material'
import {
  useAssumedSoftwareKeyboardState
} from '../../../_more/app-layout/SoftwareKeyboardProvider'
import { t } from 'i18next'

interface Props {
  sendMessage: (text: string) => void
  isSending: boolean
}

export function SendMessageBar({ sendMessage, isSending }: Props) {
  const [newMessageText, setNewMessageText] = useState('')
  const [_, setSoftwareKeyboardActive] = useAssumedSoftwareKeyboardState()
  const newMessageTextareaRef = useRef<HTMLTextAreaElement>(null)

  function handleSendMessage() {
    const text = newMessageText.trim()
    if (text.length > 0) {
      sendMessage(text)
      setNewMessageText('')
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault()
      handleSendMessage()
    }
  }

  function handleOnFocus() {
    setTimeout(() => {
      newMessageTextareaRef.current?.focus()
      setSoftwareKeyboardActive(true)
    })
  }

  return (
    <>
      <Divider />
      <HStack padding={1}>
        <Textarea
          slotProps={{ textarea: { ref: newMessageTextareaRef } }}
          minRows={1}
          maxRows={4}
          onKeyDown={handleKeyDown}
          onSubmit={() => console.log('submit')}
          placeholder={t('message')}
          className='spacer'
          value={newMessageText}
          onChange={(e) => setNewMessageText(e.target.value)}
          onFocus={handleOnFocus}
          onBlur={() => setSoftwareKeyboardActive(false)}
        />
        <IconButton onClick={handleSendMessage} loading={isSending}>
          <SendOutlined />
        </IconButton>
      </HStack>
    </>
  )
}
