import { Box, Button, ListItem, Sheet, styled, Switch } from '@mui/joy'
import { AdvancedStyles } from './advanced-styles'
import { HStack, VStack } from '../../../../lib/components/flex'
import { ToggleButton } from '@mui/material'


//------------------------------------------------------------------------------
// Navigation Bars
//------------------------------------------------------------------------------

export const ContentSheetBars = styled(Sheet)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const UserBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.2rem;  
`

// SideBar
//------------------------------------------------------------------------------

export const WrapperSideBar = styled(Box)<AdvancedStyles>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props['as-z-indices'].topBar};
  width: ${props => props['as-top-bar-height']}px;
  height: 100%;
`

export const RotatedBoxSideBar = styled(Box)`
  display: flex;
  justify-content: flex-start;
  transform: rotate(270deg);
  white-space: nowrap;
  flex-direction: row-reverse;
`

// TopBar
//------------------------------------------------------------------------------

export const WrapperTopBar = styled(Box)<AdvancedStyles>`
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    z-index: ${props => props['as-z-indices'].topBar};
    width: 100%;
    height: ${props => props['as-top-bar-height']}px;
`

// BottomBar
//------------------------------------------------------------------------------

export const WrapperBottomBar = styled(Sheet)<AdvancedStyles>`
    box-sizing: content-box;
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${props => props['as-bottom-bar-height']}px;
    z-index: ${props => props['as-z-indices'].bottomBar};
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);

    @media screen and (max-width: 600px) {
        & {
            display: block;
        }
    }
`

export const ItemButtonBottomBar = styled(Button)`
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-left: 0;
    padding-right: 0;

    &.active * {
        color: ${props => props.theme.palette.primary.plainColor};
    }
`

// LogoBar
//------------------------------------------------------------------------------

export const WrapperLogoBar = styled(Box)<AdvancedStyles>`
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    z-index: ${props => props['as-z-indices'].logoBar};
    margin-left: 8px;
    display: none;

    @media screen and (max-width: 600px) {
        & {
            display: block;
        }
    }
`

// Switcher
//------------------------------------------------------------------------------

export const SmallSwitcher = styled(ToggleButton)`
    width: 45px;
    height: 18px;
`



//------------------------------------------------------------------------------
// DrawerContent
//------------------------------------------------------------------------------

export const WrapperDrawerContent = styled(VStack)`
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);

    &.withSafeBottom {
        padding-bottom: env(safe-area-inset-bottom);
    }
`








//------------------------------------------------------------------------------
// VesselDetails
//------------------------------------------------------------------------------

export const HStackShipDataVesselDetails = styled(HStack)`
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
`

export const HStackBoxVesselDetails = styled(HStack)`
    display: flex;
    justify-content: space-between;
`





//------------------------------------------------------------------------------
// VesselDetails
//------------------------------------------------------------------------------

export const MessageWrapperDayMessageList = styled(HStack)`
    display: flex;
    width: 100%;
    padding: 4px 8px;

    &.LAGEMONITOR {
        justify-content: right;
    }
`

export const MessageListItemDayMessageList = styled(ListItem)`
    max-width: 80%;
    min-width: 20%;

    &.DEVICE_MANUAL, &.DEVICE_STANDARD, &.DEVICE_STATUS {
        background-color: ${props => props.theme.palette.neutral['800']} !important;
    }

    &.LAGEMONITOR {
        background-color: ${props => props.theme.palette.neutral['700']} !important;
    }
`




//------------------------------------------------------------------------------
// EventDetails
//------------------------------------------------------------------------------

export const DescriptionEventDetails = styled(Box)`
    * {
        color: inherit;
    }
`