import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuth } from '../../../../../app/auth'
import { serverBaseUrl } from '../../../../../app/base'
import dayjs from 'dayjs'
import { isNotPresent, isPresent } from '../../../../../lib/utils/utils'
import { lastElement } from '../../../../../lib/utils/array-utils'
import useCallOnce from '../../../../../lib/hooks/useCallOnce'
import { GlobalStarChatMessage } from '../../globalstar-model'
import { useGlobalstar } from '../../GlobalstarProvider'

export function useGlobalstarChat(deviceId: string) {
  const { authHttp } = useAuth()
  const [chatMessages, setChatMessages] = useState<GlobalStarChatMessage[]>([])
  const [isSending, setIsSending] = useState(false)
  const lastMessageIdRef = useRef<string>()
  const isLoading = useRef(false)

  const { globalstarDevices } = useGlobalstar()

  const device = useMemo(
    () => globalstarDevices.find(it => it.id === deviceId),
    [globalstarDevices, deviceId]
  )

  const groupedMessages = useMemo(() => {
    return chatMessages.reduce((acc, current) => {
      const key = current.sendTime.format('YYYY-MM-DD')
      if (isNotPresent(acc[key])) acc[key] = []
      acc[key].push(current as GlobalStarChatMessage)
      return acc
    }, {} as { [key: string]: GlobalStarChatMessage[] })
  }, [chatMessages])


  useCallOnce(() => {
    loadChatMessages()
  })

  useEffect(() => {

    const intervalId = setInterval(() => {
      console.log('intervalId', intervalId)
      loadChatMessages()
    }, 3000)

    return () => {
      clearInterval(intervalId)
    }

  }, [])

  async function loadChatMessages() {
    if (isLoading.current) return

    isLoading.current = true

    const lastMessageId = lastMessageIdRef.current
    const urlPostfix = isPresent(lastMessageId) ? `/${lastMessageId}` : ''
    const url = `${serverBaseUrl}/globalstar/chats/${deviceId}/messages${urlPostfix}`
    const { data } = await authHttp.get<GlobalStarChatMessage[]>(url)
    const messages = data.map(it => ({ ...it, sendTime: dayjs(it.sendTime) }))
    if (messages.length > 0) {
      lastMessageIdRef.current = lastElement(messages).id
      setChatMessages(prevState => [...prevState, ...messages])
    }
    isLoading.current = false
  }

  async function sendMessage(text: string) {
    setIsSending(true)
    const url = `${serverBaseUrl}/globalstar/chats/send`
    const data = { text, esn: deviceId }
    await authHttp.post(url, data)
    await loadChatMessages()
    setIsSending(false)
  }

  return {
    groupedMessages,
    sendMessage,
    device,
    isSending
  }
}
