import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { MonitorEvent } from './event'
import { useAuth } from '../../../app/auth'
import { isNotPresent } from '../../../lib/utils/utils'
import { serverBaseUrl } from '../../../app/base'
import { ChildrenProps } from '../../../lib/types'
import { EventCategory } from './components/event-categories'
import useCallOnce from '../../../lib/hooks/useCallOnce'
import { isAllowed } from '../_more/filter/filter-utils'
import useLocalStorageState from 'use-local-storage-state'

interface ProvidedData {
  events: MonitorEvent[]
  filteredEvents: MonitorEvent[]
  mapEvents: MonitorEvent[]
  categoriesFilter: EventCategory[]
  setCategoriesFilter: (categories: EventCategory[]) => void
  sourceFilter: string[]
  setSourceFilter: (sources: string[]) => void
  showInMap: boolean
  setShowInMap: (showInMap: boolean) => void
}

const EventContext = createContext<ProvidedData>({
  events: [],
  filteredEvents: [],
  mapEvents: [],
  categoriesFilter: [],
  setCategoriesFilter: () => undefined,
  sourceFilter: [],
  setSourceFilter: () => undefined,
  showInMap: true,
  setShowInMap: () => undefined
})

export function EventProvider({ children }: ChildrenProps) {
  const eventLogic = useEventLogic()

  return (
    <EventContext.Provider value={eventLogic}>
      {children}
    </EventContext.Provider>
  )
}

export function useEvents() {
  return useContext(EventContext)
}


function useEventLogic() {
  const { authHttp } = useAuth()
  const [events, setEvents] = useState<MonitorEvent[]>([])
  const [filteredEvents, setFilteredEvents] = useState<MonitorEvent[]>([])

  const [categoriesFilter, setCategoriesFilter] = useState<EventCategory[]>([])
  const [sourceFilter, setSourceFilter] = useState<string[]>([])

  const [showInMap, setShowInMap] = useLocalStorageState('show-events-in-map', { defaultValue: false })

  const mapEvents = useMemo(
    () => showInMap ? filteredEvents : [],
    [filteredEvents, showInMap]
  )

  useEffect(() => {
    //loadData()
  }, [])

  useCallOnce(() => {
    setInterval(() => {
      // loadData()
    }, 60000)
  })

  useEffect(() => {
    if (isNotPresent(categoriesFilter)) {
      setFilteredEvents(events)
      return
    }


    setFilteredEvents(events.filter(event => {
      const categoryCodes = categoriesFilter.map(it => it.code)

      return isAllowed(categoryCodes, event.category) && isAllowed(sourceFilter, event.source)
    }))
  }, [events, categoriesFilter, sourceFilter])


  useEffect(() => {
  })

  async function loadData() {
    console.log('load events')
    const { data } = await authHttp.get(`${serverBaseUrl}/events`)
    setEvents(data)
  }

  return {
    events,
    filteredEvents,
    mapEvents,
    categoriesFilter,
    setCategoriesFilter,
    sourceFilter,
    setSourceFilter,
    showInMap,
    setShowInMap
  }
}
