import React from 'react'
import { useParams } from 'react-router-dom'
import { useEvents } from '../EventProvider'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { isNotPresent } from '../../../../lib/utils/utils'
import { Typography } from '@mui/material'
import { DescriptionEventDetails } from '../../_more/app-layout/styled-components'
import { t } from 'i18next'


export function EventDetails() {
  const eventId: string = useParams().eventId!
  const { events } = useEvents()

  const event = events.find(it => it.id === eventId)

  if (isNotPresent(event)) return (
    <DrawerContent title='' backUrl='/events'>
      {t('eventNotFound')}
    </DrawerContent>
  )

  return (
    <DrawerContent title={''} backUrl='/events'>
      <Typography variant='h6'>{event.title}</Typography>
      <br/>
      <DescriptionEventDetails
        dangerouslySetInnerHTML={{ __html: event.description ?? '' }} />
    </DrawerContent>
  )
}