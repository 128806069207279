import React from 'react'
import { useParams } from 'react-router-dom'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { isNotPresent } from '../../../../lib/utils/utils'
import { Divider, Typography } from '@mui/joy'
import { VStack } from '../../../../lib/components/flex'
import { useFlight } from '../FlightRadarProvider'
import { HStackBoxVesselDetails, HStackShipDataVesselDetails } from '../../_more/app-layout/styled-components'
import { t } from 'i18next'

export function FlightDetails() {
  const flightHex: string = useParams().flightHex!
  const { flightData } = useFlight()

  const flight = flightData.find(it => it.hex === flightHex)

  if (isNotPresent(flight)) return (
    <DrawerContent title='' backUrl='/flight-radar'>
      {t('flightNotFound')}
    </DrawerContent>
  )

  return (
    <DrawerContent title={flight.r} backUrl='/flight-radar'>
      <VStack>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Reg:</Typography>
            <Typography>{flight.r}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Type:</Typography>
            <Typography>{flight.t}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
        <Divider sx={{ '--Divider-childPosition': '50%' }}>
          {t('position')}
        </Divider>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Lat:</Typography>
            <Typography>{flight.lat}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Lon:</Typography>
            <Typography>{flight.lon}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Altitude:</Typography>
            <Typography>{flight.alt_geom}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Heading:</Typography>
            <Typography>{flight.nav_heading ? flight.nav_heading : 'N/A'}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Speed:</Typography>
            <Typography>{flight.gs}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Geom Rate:</Typography>
            <Typography>{flight.geom_rate}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Track:</Typography>
            <Typography>{flight.track}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Updated:</Typography>
            <Typography>{flight.seen_pos}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
        <Divider sx={{ '--Divider-childPosition': '50%' }}>
          {t('safety')}
        </Divider>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Signal:</Typography>
            <Typography>{flight.rssi}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>SOS:</Typography>
            <Typography>{flight.emergency ? flight.emergency : 'No'}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Alert:</Typography>
            <Typography>{flight.alert}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Spi:</Typography>
            <Typography>{flight.spi}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
        <HStackBoxVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>Squawk:</Typography>
            <Typography>{flight.squawk}</Typography>
          </HStackShipDataVesselDetails>
          <HStackShipDataVesselDetails>
            <Typography>MSG:</Typography>
            <Typography>{flight.messages}</Typography>
          </HStackShipDataVesselDetails>
        </HStackBoxVesselDetails>
      </VStack>
    </DrawerContent>
  )
}
