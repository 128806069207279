import React, { useRef } from 'react'
import { Button, TextField } from '@mui/material'
import { VStack } from '../../../../lib/components/flex'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { useParams } from 'react-router-dom'
import { useReports } from '../ReportProvider'
import { isPresent } from '../../../../lib/utils/utils'
import { Report } from '../report-model'
import { t } from 'i18next'

interface Props {

}

export function ReportEditor({}: Props) {

  const reportId: string | undefined = useParams().reportId

  const { reports, saveReport } = useReports()

  const optReport = isPresent(reportId) ? reports.find(it => it.id === reportId) : undefined
  const report: Report = optReport ?? {
    id: undefined,
    title: '',
    description: '',
    latitude: 52.50607 + Math.random(),
    longitude: 13.32135 + Math.random(),
    category: ''
  }

  const titleRef = useRef<HTMLInputElement>(null)
  const descriptionRef = useRef<HTMLInputElement>(null)

  function handleSave() {
    saveReport({
      id: report.id,
      title: titleRef.current?.value ?? '',
      description: descriptionRef.current?.value ?? '',
      latitude: report.latitude,
      longitude: report.longitude,
      category: report.category,
    })
  }

  return (
    <DrawerContent title={isPresent(reportId) ? t('edit') : t('create')}
                   backUrl='/reports'>
      <VStack gap={2}>
        <TextField inputRef={titleRef} label={t('title')} fullWidth
                   defaultValue={report.title}
        />
        <TextField inputRef={descriptionRef} label={t('description')} fullWidth
                   defaultValue={report.description}
                   multiline
                   minRows={5} maxRows={10}
        />
        <Button variant='contained' onClick={handleSave}>{t('save')}</Button>
      </VStack>
    </DrawerContent>
  )
}
