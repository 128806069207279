import React from 'react'
import { IconButton } from '@mui/joy'
import { SettingsOutlined } from '@mui/icons-material'
import { DeviceList } from './DeviceList'
import { DrawerContent } from '../../../_more/app-layout/DrawerContent'
import { useNavigate } from 'react-router'
import { t } from 'i18next'

export function DeviceContent() {

  const navigate = useNavigate()

  return (
    <DrawerContent
      title={t('globalstar')}
      backUrl='/'
      actions={
        <IconButton onClick={() => navigate('keys')}>
          <SettingsOutlined />
        </IconButton>
      }
    >
      <DeviceList />
    </DrawerContent>
  )
}
