import React from 'react'
import { ChildrenProps } from '../../lib/types'
import {
  SoftwareKeyboardProvider
} from './_more/app-layout/SoftwareKeyboardProvider'
import { BottomBarProvider } from './_more/app-layout/BottomBarProvider'
import { EventProvider } from './events/EventProvider'
import { GlobalstarProvider } from './globalstar/GlobalstarProvider'
import { ReportProvider } from './reports/ReportProvider'
import { FlightProvider } from './flight-radar/FlightRadarProvider'
import { VesselProvider } from './maritime/MaritimeProvider'

export function MonitorProviders({ children }: ChildrenProps) {
  return (
    <>
      <SoftwareKeyboardProvider>
        <BottomBarProvider>
          <EventProvider>
            <GlobalstarProvider>
              <ReportProvider>
                <FlightProvider>
                  <VesselProvider>
                    {children}
                  </VesselProvider>
                </FlightProvider>
              </ReportProvider>
            </GlobalstarProvider>
          </EventProvider>
        </BottomBarProvider>
      </SoftwareKeyboardProvider>
    </>
  )
}
