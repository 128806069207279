import { Box } from '@mui/joy'
import { ChildrenProps } from '../../../../lib/types'
import { DisplayNoneInSmallMode, useAdvancedStyles } from './advanced-styles'
import { Spacer, VStack } from '../../../../lib/components/flex'
import { useLocation } from 'react-router-dom'
import React, { Children, cloneElement, isValidElement } from 'react'
import { UserInfo } from './UserInfo'
import { WrapperSideBar, ContentSheetBars, RotatedBoxSideBar, UserBox } from './styled-components'


interface SideBarProps extends ChildrenProps {
  onSwitchView: () => void
}

export function SideBar({ children, onSwitchView }: SideBarProps) {
  const styles = useAdvancedStyles()
  const location = useLocation()


  return (
    <DisplayNoneInSmallMode>
      <WrapperSideBar {...styles}>
        <ContentSheetBars variant='plain'>
          <VStack>
            <Box sx={{ height: 10 }} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src='/favicon.ico' alt='' height={32} width={32} />
            </Box>

            <Box sx={{ height: 10 }} />
            <RotatedBoxSideBar>
              {Children.map(children, (child) =>
                isValidElement(child) ? cloneElement(child, { activeRoute: location.pathname } as any) : child
              )}
            </RotatedBoxSideBar>

            <Spacer />
            <UserBox >
              <UserInfo location="side" onSwitchView={onSwitchView}/>
            </UserBox>
          </VStack>
        </ContentSheetBars>
      </WrapperSideBar>
    </DisplayNoneInSmallMode>
  )
}