import React from 'react'
import { Box, Sheet, styled } from '@mui/joy'
import { ChildrenProps } from '../../../../lib/types'
import classNames from 'classnames'
import { AdvancedStyles, useAdvancedStyles } from './advanced-styles'

interface ComponentProps extends ChildrenProps {
  open: boolean
}

interface StyleProps {
  width: number
  left: number
  top: number
}

export function Drawer({ width, open, left, top, children }: ComponentProps & StyleProps) {
  const styles = useAdvancedStyles()

  const classes = classNames({
    'open': open
  })

  return (
    <Wrapper width={width} left={left} top={top} className={classes} {...styles}>
      <Sheet
        variant={styles['as-main-variant']}
        sx={{ width: '100%', height: '100%' }}
      >
        {children}
      </Sheet>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<StyleProps & AdvancedStyles>`
    position: fixed;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    z-index: ${props => props['as-z-indices'].drawer};
    width: ${props => props.width}px;
    height: 100%;
    transform: translateX(-${props => props.width}px);

    &.open {
        transform: translateX(0);
    }

    @media screen and (max-width: ${props => props['as-small-mode-width']}px) {
        & {
            transform: translateX(-100%);
            width: 100%;
        }
    }
`
