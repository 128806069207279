import React, { useState } from 'react'
import {
  Button,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemContent,
  Typography,
} from '@mui/joy'
import { Delete } from '@mui/icons-material'
import { DrawerContent } from '../../../_more/app-layout/DrawerContent'
import { VStack } from '../../../../../lib/components/flex'
import { useGlobalstarKeys } from './useGlobalstarKeys'
import { t } from 'i18next'

export function GlobalstarKeyContent() {
  const { keys, isCreating, addKey, deleteKey } = useGlobalstarKeys()

  const [keyName, setKeyName] = useState('')
  const [keyValue, setKeyValue] = useState('')

  function handleAddKey() {
    addKey(keyName, keyValue)
    setKeyName('')
    setKeyValue('')
  }

  return (
    <DrawerContent title='Globalstar Keys' backUrl='/globalstar'>
      <VStack gap={1} padding={1}>
        <Input
          value={keyName}
          onChange={(e) => setKeyName(e.target.value)}
          variant='outlined'
          placeholder='Name'
        />
        <Input
          value={keyValue}
          onChange={(e) => setKeyValue(e.target.value)}
          variant='outlined'
          placeholder='Wert'
        />
        <Button
          variant='solid'
          onClick={handleAddKey}
          loading={isCreating}
          disabled={keyName.length === 0 || keyValue.length === 0}
        >
          {t('add')}
        </Button>

        <List>
          {keys.map(key => (
            <ListItem
              key={key.id!!}
              endAction={
                <IconButton
                  color='danger'
                  onClick={() => deleteKey(key.id!!)}>
                  <Delete />
                </IconButton>
              }
            >
              <ListItemContent>
                <Typography>{key.name}</Typography>
                <Typography level='body-xs'>{key.value}</Typography>
              </ListItemContent>
            </ListItem>
          ))}
        </List>

      </VStack>
    </DrawerContent>
  )
}
