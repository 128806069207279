import { isNotPresent } from '../../../../lib/utils/utils'

export function isAllowed(filterValues: string[], value?: string) {
  if (filterValues.length === 0) return true

  if (isNotPresent(value) || value === '') {
    return filterValues.includes('empty')
  }

  if (filterValues.includes('all')) return true

  return filterValues.includes(value)
}

export interface FilterSection {
  title: string
  categories: FilterSectionCategory[]
}

export interface FilterSectionCategory {
  key: string
  name: string
  code: string
}

export interface FilterCategory {
  title: string
  name: string
  key: string
  code: string
}

export function mergeFilter(filterSections: FilterSection[]) {
  return filterSections.reduce((acc, value) => {
    const mergedCategories =
      value.categories.map(category => ({
        ...category,
        title: value.title
      }))
    return [...acc, ...mergedCategories]
  }, [] as FilterCategory[])
}

export function t(s: string) {
  return s
}

export function isAllowedShip(filter: string[], shipType: string): boolean {
  if (filter.length === 0) return true
  const firstDigit = shipType.charAt(0)
  return filter.includes(firstDigit)
}