import { Button, styled } from '@mui/joy'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

interface StyledButtonProps extends React.ComponentProps<typeof Button> {
  to: string
}

export const StyledButton: React.FC<StyledButtonProps> = ({ to, ...props }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    if (location.pathname === to) {
      navigate('/', { replace: true })
    } else {
      navigate(to, { replace: true })
    }
  }

  return (
    <StyledButtonBase
      {...props}
      to={to}
      isActive={location.pathname === to}
      onClick={handleClick}
    />
  )
}

const StyledButtonBase = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ to: string; isActive: boolean }>(({ isActive }) => ({
  marginRight: '5px',
  color: isActive ? '#ffffff' : 'inherit',
  backgroundColor: isActive ? '#ffffff10' : 'inherit',
  '&:hover': {
    backgroundColor: '#ffffff5',
  },
}))
