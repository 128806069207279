import React from 'react'
import { DisplayBlockInSmallMode, useAdvancedStyles } from './advanced-styles'
import { WrapperLogoBar } from './styled-components'

interface Props {

}

export function LogoBar({}: Props) {
  const styles = useAdvancedStyles()

  return (
    <DisplayBlockInSmallMode>
      <WrapperLogoBar {...styles}>
        <img src='/res/images/qntrol-logo-white.svg' alt=''
             height={32} />
      </WrapperLogoBar>
    </DisplayBlockInSmallMode>
  )
}