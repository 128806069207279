import { useEffect, useState } from 'react'
import { useAuth } from '../../app/auth'
import { serverBaseUrl } from '../../app/base'

export interface UserInfo {
  email: string,
  organizationName: string
}

export function useUserInfo() {
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const { authHttp } = useAuth()

  useEffect(() => {
    authHttp.get<UserInfo>(`${serverBaseUrl}/authentication/info`)
      .then(({ data }) => {
        setUserInfo(data)
      })
  }, [])

  return userInfo
}
