import React, {
  createContext, useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAuth } from '../../../app/auth'
import { serverBaseUrl } from '../../../app/base'
import { ChildrenProps } from '../../../lib/types'
import { Vessel, VesselDataETA } from './maritime-model'
import useCallOnce from '../../../lib/hooks/useCallOnce'
import useLocalStorageState from 'use-local-storage-state'
import { chill } from '../../../lib/utils/utils'
import dayjs from 'dayjs'

interface ProvidedData {
  vesselData: Vessel[]
  mapVesselData: Vessel[]
  showInMap: boolean
  setShowInMap: (flag: boolean) => void
  fromUnixTime: number
  toUnixTime: number
  setFromUnixTime: (time: number) => void
  setToUnixTime: (time: number) => void
  typeFilter: string[]
  setTypeFilter: (sources: string[]) => void
  etaFilterEnabled: boolean
  setEtaFilterEnabled: (enabled: boolean) => void
}

const VesselContext = createContext<ProvidedData>({
  vesselData: [],
  mapVesselData: [],
  showInMap: true,
  setShowInMap: chill,
  fromUnixTime: 0,
  toUnixTime: 0,
  setFromUnixTime: chill,
  setToUnixTime: chill,
  typeFilter: [],
  setTypeFilter: () => undefined,
  etaFilterEnabled: true,
  setEtaFilterEnabled: () => undefined,
})

export function VesselProvider({ children }: ChildrenProps) {
  const vesselLogic = useVesselLogic()

  return (
    <VesselContext.Provider value={vesselLogic}>
      {children}
    </VesselContext.Provider>
  )
}

export const useVessel = () => useContext(VesselContext);


function useVesselLogic() {
  const { authHttp } = useAuth()
  const [vesselData, setVesselData] = useState<Vessel[]>([])
  const [typeFilter, setTypeFilter] = useState<string[]>([])
  const [etaFilterEnabled, setEtaFilterEnabled] = useState<boolean>(false)

  const [showInMap, setShowInMap] = useLocalStorageState('show-maritime-in-map', { defaultValue: false })

  const [fromUnixTime, setFromUnixTime] = useState<number>(dayjs().subtract(7, 'days').unix())

  const [toUnixTime, setToUnixTime] = useState<number>(dayjs().add(7, 'days').unix())

  /*
  const [fromUnixTime, setFromUnixTime] = useLocalStorageState(
    'maritime-from-unix-time',
    { defaultValue: dayjs().subtract(7, 'days').unix() }
  )

  const [toUnixTime, setToUnixTime] = useLocalStorageState(
    'maritime-to-unix-time',
    { defaultValue: dayjs().add(7, 'days').unix() }
  )*/


  useEffect(() => {
    //loadData().then(r => console.log(r))
  }, [])

  useCallOnce(() => {
    const intervalId = setInterval(() => {
     //loadData().then(r => console.log(r))
    }, 10000)

    return () => clearInterval(intervalId)
  })

  async function loadData() {
    console.log('load maritime traffic')
    try {
      const { data } = await authHttp.get<Vessel[]>(`${serverBaseUrl}/marine-traffic/allVessels`)
      setVesselData(data)
    } catch (error) {
      console.error('Failed to load vessel data', error)
    }
  }

  const getMapData = useCallback(() => {
    const vesselToShow = vesselData.filter(vessel =>
      (typeFilter.length === 0 || typeFilter.includes(String(Math.floor(vessel.SHIPTYPE / 10)))) &&
      (!etaFilterEnabled || (vessel.eta && vessel.eta.some(eta => {
        const etaUnixTime = dayjs(eta.unixTime).unix()
        return fromUnixTime <= etaUnixTime && etaUnixTime <= toUnixTime
      })))
    )

    return getVesselDataInTimeRange(vesselToShow, fromUnixTime, toUnixTime)
  }, [vesselData, fromUnixTime, toUnixTime, typeFilter, etaFilterEnabled])

  const mapVesselData = useMemo(
    () => (showInMap ? getMapData() : []),
    [getMapData, showInMap]
  )

  function getVesselDataInTimeRange(
    vessel: Vessel[],
    fromUnixTime: number,
    toUnixTime: number
  ) {
    function filteredETA(eta: VesselDataETA) {
      return eta?.filter(
        (it) => {
          const etaUnixTime = dayjs(it.unixTime).unix()
          return fromUnixTime <= etaUnixTime && etaUnixTime <= toUnixTime
        }
      )
    }

    return vessel.map((it) => ({
      ...it,
      eta: filteredETA(it.eta),
    }))
  }

  return {
    vesselData,
    mapVesselData,
    showInMap,
    setShowInMap,
    fromUnixTime,
    toUnixTime,
    setFromUnixTime,
    setToUnixTime,
    typeFilter,
    setTypeFilter,
    etaFilterEnabled,
    setEtaFilterEnabled,
  }
}
