import {GlobalstarDevice, GlobalstarDeviceHistory} from '../../../globalstar/globalstar-model'

export const useGlobalstarGeoJson = (globalstarData: any[] | undefined) => {
    if (globalstarData && globalstarData.length > 0)
        return {
            type: 'FeatureCollection',
            features: globalstarData!!.map((device) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: device.history && device.history.length > 0 ? [device.history[0].longitude, device.history[0].latitude] : [0, 0],
                },
                properties: {
                    color: rgbToHex(device.color),
                    modelId: device.modelId,
                    id: device.id,
                    name: device.name,
                },
            })),
        }
    return {
        type: 'FeatureCollection',
        features: []
    }
}

function rgbToHex(rgb: any[]) {
    return '#' + rgb.map(value => {
        const hex = value.toString(16);
        return hex.length === 1 ? '0' + hex : hex; // Add leading zero if necessary
    }).join('');
}
