import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useAuth } from '../../../app/auth'
import { serverBaseUrl } from '../../../app/base'
import { ChildrenProps } from '../../../lib/types'
import { GlobalstarDevice, GlobalstarDeviceHistory } from './globalstar-model'
import { chill } from '../../../lib/utils/utils'
import useCallOnce from '../../../lib/hooks/useCallOnce'
import useLocalStorageState from 'use-local-storage-state'
import dayjs from 'dayjs'

interface ProvidedData {
  globalstarDevices: GlobalstarDevice[]
  mapGlobalstarDevices: GlobalstarDevice[]
  showInMap: boolean,
  setShowInMap: (flag: boolean) => void,
  hiddenMessengerIds: string[],
  toggleHiddenMessengerId: (id: string) => void,
  fromUnixTime: number,
  toUnixTime: number,
  setFromUnixTime: (time: number) => void,
  setToUnixTime: (time: number) => void,
}

const GlobalstarContext = createContext<ProvidedData>({
  globalstarDevices: [],
  mapGlobalstarDevices: [],
  showInMap: true,
  setShowInMap: chill,
  hiddenMessengerIds: [],
  toggleHiddenMessengerId: chill,
  fromUnixTime: 0,
  toUnixTime: 0,
  setFromUnixTime: chill,
  setToUnixTime: chill,
})

export function GlobalstarProvider({ children }: ChildrenProps) {
  const globalstarLogic = useGlobalstarLogic()

  return (
    <GlobalstarContext.Provider value={globalstarLogic}>
      {children}
    </GlobalstarContext.Provider>
  )
}

export function useGlobalstar() {
  return useContext(GlobalstarContext)
}


function useGlobalstarLogic() {
  const { authHttp } = useAuth()
  const [globalstarDevices, setGlobalstarDevices] = useState<GlobalstarDevice[]>([])

  const [showInMap, setShowInMap] = useState(true)
  const [hiddenMessengerIds, setHiddenMessengerIds] = useState<string[]>([])
  const [fromUnixTime, setFromUnixTime] = useLocalStorageState(
    'globalstar-from-unix-time',
    { defaultValue: dayjs().subtract(7, 'days').unix() }
  )

  const [toUnixTime, setToUnixTime] = useLocalStorageState(
    'globalstar-to-unix-time',
    { defaultValue: dayjs().add(7, 'days').unix() }
  )

  const mapGlobalstarDevices = useMemo(
    () => showInMap ? getMapData() : [],
    [globalstarDevices, showInMap, hiddenMessengerIds, fromUnixTime, toUnixTime]
  )

  useEffect(() => {
    loadData()
  }, [])

  useCallOnce(() => {
    setInterval(() => {
      loadData()
    }, 10000)
  })

  function getColor(index: number) {
    return globalstarColors[index % globalstarColors.length]
  }

  async function loadData() {
    console.log('load globalstar devices')
    const { data } = await authHttp.get<GlobalstarDevice[]>(`${serverBaseUrl}/globalstar/devices`)
    const devices = data.map((device, index) => ({
      ...device,
      color: getColor(index)
    }))
    setGlobalstarDevices(devices)
  }

  function toggleHiddenMessengerId(id: string) {
    setHiddenMessengerIds(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(it => it !== id)
      }
      return [...prevState, id]
    })
  }

  function getMapData() {
    const devicesToShow =
      globalstarDevices.filter(it => !hiddenMessengerIds.includes(it.id))

    return getDeviceDataInTimeRange(
      devicesToShow,
      fromUnixTime,
      toUnixTime,
    )
  }

  function getDeviceDataInTimeRange(
    devices: GlobalstarDevice[],
    fromUnixTime: number,
    toUnixTime: number
  ) {
    function filteredHistory(history: GlobalstarDeviceHistory) {
      return history.filter(it =>
        fromUnixTime <= it.unixTime && it.unixTime <= toUnixTime
      )
    }

    return devices.map(it => ({
      ...it,
      history: filteredHistory(it.history)
    }))
  }

  return {
    globalstarDevices,
    mapGlobalstarDevices,
    showInMap,
    setShowInMap,
    hiddenMessengerIds,
    toggleHiddenMessengerId,
    fromUnixTime,
    toUnixTime,
    setFromUnixTime,
    setToUnixTime
  }
}

const globalstarColors = [
  [15, 104, 60],
  [15, 80, 104],
  [61, 15, 104],
  [104, 42, 15],
  [15, 16, 104],
  [104, 15, 15],
  [104, 60, 15],
  [99, 104, 15],
  [104, 79, 15],
  [104, 15, 83],
]
