import {GlobalstarDevice, GlobalstarDeviceHistory} from '../../../globalstar/globalstar-model'

export const useFlightGeoJson = (mapFlightData: any[] | undefined) => {
    return {
        type: 'FeatureCollection',
        features: mapFlightData!!.map((flight) => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [flight.lon, flight.lat],
            },
            properties: {
                flight: flight.flight,
                heading: flight.nav_heading,
                t: flight.t,
                hex: flight.hex,
            },
        })),
    }
}


