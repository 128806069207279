import React from 'react'
import { isNotPresent } from '../../../../../lib/utils/utils'
import dayjs from 'dayjs'
import { DetailBox } from '../../../_more/components/base-components'
import { Typography } from '@mui/joy'
import { GlobalstarDevice } from '../../globalstar-model'
import { t } from 'i18next'

interface Props {
  device: GlobalstarDevice
}

export function DeviceDetails({ device }: Props) {
  const latest = device.history && device.history.length > 0 ? device.history[0] : null

  if (isNotPresent(latest)) {
    return <></>
  }

  const time = dayjs.unix(latest.unixTime).format('DD.MM.YYYY HH:mm')

  return (
    <DetailBox>
      <Typography level='body-xs' sx={{ display: 'flex' }} noWrap>
        {t('latestGlobalStar')}: <span className='spacer' />{time}
      </Typography>
      <Typography level='body-xs' sx={{ display: 'flex' }} noWrap>
        {t('location')}: <span
        className='spacer' />{latest.latitude}, {latest.longitude}
      </Typography>
      <Typography level='body-xs' sx={{ display: 'flex' }} noWrap>
        {t('battery')}: <span className='spacer' /> {device.batteryState}
      </Typography>
    </DetailBox>
  )
}
