import { Box, styled } from '@mui/joy'
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers'
import React, { Fragment } from 'react'

export const DetailBox = styled(Box)`
    border-left: 4px solid ${props => props.theme.palette.neutral.outlinedBorder};
    padding: 4px;
`

export const DateTimePicker = styled(MuiDateTimePicker)`
    margin-right: 8px;

    & fieldset {
        border: none !important;
    }

    * {
        width: unset !important;
        padding: 0 !important;
    }
`

export function Multiline({ text }: { text: string }) {
  const lines = text.split('\n')
  const lastIndex = lines.length - 1

  return (
    <>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {index < lastIndex && <br />}
        </Fragment>
      ))}
    </>
  )
}
