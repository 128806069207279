import React, { useState, useEffect, useCallback } from 'react'
import { Divider, List, ListItemButton } from '@mui/material'
import { useNavigate } from 'react-router'
import { Box, Typography, styled } from '@mui/joy'
import { HStack, VStack } from '../../../../lib/components/flex'
import EastIcon from '@mui/icons-material/East'
import { format } from 'date-fns'
import { useVessel } from '../MaritimeProvider'
import { useInView } from 'react-intersection-observer'
import { Vessel } from '../maritime-model'
import { LoadingIndicator } from '../../_more/app-layout/LoadingIndicator'

export function VesselList() {
  const { vesselData } = useVessel()
  const navigate = useNavigate()
  const vesselsPerPage = 20
  const [loadedVessels, setLoadedVessels] = useState<Vessel[]>([])
  const [page, setPage] = useState(1)
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.15,
  })

  useEffect(() => {
    loadMoreVessels()
  }, [])

  useEffect(() => {
    if (inView) {
      loadMoreVessels()
    }
  }, [inView])

  const loadMoreVessels = useCallback(() => {
    const nextPage = page + 1
    const newVessels = vesselData.slice(
      0,
      vesselsPerPage * nextPage
    );
    setLoadedVessels(newVessels)
    setPage(nextPage)
  }, [page, vesselData])

  const formatETA = (eta: string | number | Date | null) =>
    eta ? format(new Date(eta), 'dd.MM HH:mm') : ''

  const getCallSign = (callSign: string) =>
    callSign === '' ? 'Unknown' : callSign

  const getDestination = (destination: string) =>
    destination === '' ? 'UNKNOWN' : destination

  return (
    <>
      <List sx={{ width: '100%' }}>
        {loadedVessels.map((vessel) => (
          <React.Fragment key={vessel.MMSI!!}>
            <ListItemButton onClick={() => navigate(`/maritim/${vessel.MMSI!!}`)}>
              <VStack>
                <HStack sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>{getCallSign(vessel.CALLSIGN)}</Typography>
                </HStack>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={{ fontSize: '0.7rem' }}>{formatETA(vessel.ETA)}</Typography>
                </Box>
                <HStack sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <PortTypography>{vessel.LAST_PORT}</PortTypography>
                  <EastIcon sx={{ width: '10%', fontSize: '0.7rem' }} />
                  <PortTypography>{getDestination(vessel.DESTINATION)}</PortTypography>
                </HStack>
              </VStack>
            </ListItemButton>
            <Divider component="li" />
          </React.Fragment>
        ))}
      </List>
      <Box ref={ref} sx={{ display: loadedVessels.length < vesselData.length ? 'block' : 'none' }}>
        <LoadingIndicator loading={true} />
      </Box>
    </>
  )
}

const PortTypography = styled(Typography)`
    width: 45%;
    max-width: 45%;
    white-space: nowrap;
    font-size: 0.7rem;
    text-align: center;
`
