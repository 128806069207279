import React, {useEffect, useRef} from 'react'
import mapboxgl from 'mapbox-gl'
import {useEvents} from '../../events/EventProvider'
import 'mapbox-gl/dist/mapbox-gl.css'
import {useReports} from '../../reports/ReportProvider'
import {useLocation} from 'react-router'
import {createRandomMarkers} from './dataTemp/marker'
import {loadImages} from './utils/mapUtils'
import {addMaritimeMarkers} from './utils/maritimeMarkers'
import {
    handleMouseClickFlight,
    handleMouseClickGlobalstar,
    handleMouseEnter,
    handleMouseLeave,
} from './utils/eventHandler'
import {addPolygonToMap} from './utils/polygonUtils'
import {useVessel} from '../../maritime/MaritimeProvider'
import {mapboxAccessToken} from '../../../../app/base'
import {useNavigate} from 'react-router'
import airplaneIcon from './icons/airplane.png' // no need to ts-ignore, image paths should be resolved properly
import helicopterIcon from './icons/helicopter.png'
import gen4Icon from './icons/globalstar/gen4_border_1.png'
import spotxIcon from './icons/globalstar/spotx_border_1.png'
import traceIcon from './icons/globalstar/trace_border_1.png'
import {useFlightGeoJson} from './utils/convertFlightData'
import {useGlobalstarGeoJson} from './utils/convertGlobalstarData'
import {useFlight} from '../../flight-radar/FlightRadarProvider'
import {useGlobalstar} from '../../globalstar/GlobalstarProvider'

const MonitorMap: React.FC = () => {
    const mapContainerRef = useRef<HTMLDivElement | null>(null)
    const mapRef = useRef<mapboxgl.Map | null>(null)

    const navigate = useNavigate()
    const location = useLocation() // Get location to retrieve saved state
    const savedMapState = location.state as { center: mapboxgl.LngLatLike, zoom: number } | undefined

    const {mapEvents} = useEvents()
    const {mapReports} = useReports()
    const {mapVesselData} = useVessel()
    const {mapFlightData} = useFlight()
    const flightGeoJson = useFlightGeoJson(mapFlightData)

    const {mapGlobalstarDevices} = useGlobalstar()
    const globalStarGeoJson = useGlobalstarGeoJson(mapGlobalstarDevices)

    useEffect(() => {
        mapboxgl.accessToken = mapboxAccessToken

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        })

        if (mapContainerRef.current) {
            //------------------------------------------------------------------------------------------------------------
            // Initialize Map
            //------------------------------------------------------------------------------------------------------------
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                // style: 'mapbox://styles/mapbox/dark-v9',
                center: savedMapState?.center || [10.254126, 51.431464],
                zoom: savedMapState?.zoom || 5,
                projection: 'globe',
                attributionControl: false,
            })

            mapRef.current.on('style.load', () => {
                // Load Vessel - Images/Icons
                loadImages(mapRef.current!, () => addMaritimeMarkers(mapRef.current!, mapVesselData, navigate))

                if (mapRef.current) {
                    // Load Reports
                    /*mapRef.current.addSource('report-marker', {
                        type: 'geojson',
                        data: createRandomMarkers(100) as GeoJSON.FeatureCollection,
                    })

                    mapRef.current.addLayer({
                        id: 'report-marker',
                        type: 'circle',
                        source: 'report-marker',
                        paint: {
                            'circle-color': '#C0FF00',
                            'circle-radius': 6,
                            'circle-stroke-width': 2,
                            'circle-stroke-color': '#ffffff',
                        },
                    })

                    mapRef.current.on('mouseenter', 'report-marker', handleMouseEnter(mapRef.current, popup))
                    mapRef.current.on('mouseleave', 'report-marker', handleMouseLeave(mapRef.current, popup))

                     */

                    //------------------------------------------------------------------------------------------------------------
                    // Load Flight Images
                    //------------------------------------------------------------------------------------------------------------
                    mapRef.current.loadImage(airplaneIcon, (error1, airplainIcon) => {
                        if (error1) {
                            console.error('Error loading image:', error1)
                            return
                        }

                        mapRef.current!.loadImage(helicopterIcon, (error2, helicopterIcon) => {
                            if (error2) {
                                console.error('Error loading image:', error2)
                                return
                            }


                            if (airplainIcon) {
                                mapRef.current!.addImage('airplane-icon', airplainIcon)
                            }
                            if (helicopterIcon) {
                                mapRef.current!.addImage('helicopter-icon', helicopterIcon)
                            }


                            // Initially add flights source and layer
                            if (!mapRef.current!.getSource('flights')) {
                                mapRef.current!.addSource('flights', {
                                    type: 'geojson',
                                    data: flightGeoJson as GeoJSON.FeatureCollection,
                                })

                                mapRef.current!.addLayer({
                                    id: 'flight-layer',
                                    type: 'symbol',
                                    source: 'flights',
                                    layout: {
                                        'icon-image': 'airplane-icon',
                                        'icon-size': 0.2,
                                        'icon-allow-overlap': true,
                                        'icon-rotate': ['get', 'heading'],
                                    },
                                })

                                mapRef.current!.on('click', 'flight-layer', handleMouseClickFlight(mapRef.current!, navigate))
                            }

                        })
                    })

                    //------------------------------------------------------------------------------------------------------------
                    // Load Globalstar Images
                    //------------------------------------------------------------------------------------------------------------
                    mapRef.current.loadImage(gen4Icon, (error1, gen4Icon) => {
                        if (error1) {
                            console.error('Error loading image:', error1)
                        }

                        if (gen4Icon) {
                            mapRef.current!.addImage('gen4-icon', gen4Icon)
                        }

                        mapRef.current!.loadImage(spotxIcon, (error2, spotxIcon) => {
                            if (error2) {
                                console.error('Error loading image:', error2)
                            }

                            if (spotxIcon) {
                                mapRef.current!.addImage('spotx-icon', spotxIcon)
                            }

                            mapRef.current!.loadImage(traceIcon, (error3, traceIcon) => {
                                if (error3) {
                                    console.error('Error loading image:', error3)
                                }

                                if (traceIcon) {
                                    mapRef.current!.addImage('trace-icon', traceIcon)
                                }

                                if (!mapRef.current!.getSource('globalstar')) {
                                    mapRef.current!.addSource('globalstar', {
                                        type: 'geojson',
                                        data: globalStarGeoJson as GeoJSON.FeatureCollection,
                                    })

                                    mapRef.current!.addLayer({
                                        id: 'globalstar-layer',
                                        type: 'symbol',
                                        source: 'globalstar',
                                        layout: {
                                            'icon-image': ['match', ['get', 'type'], 'GEN4', 'gen4-icon', 'SPOTX', 'spotx-icon', 'SPOTTRACE', 'trace-icon', 'gen4-icon'],
                                            'icon-size': 0.05,
                                            'icon-allow-overlap': true,
                                            'text-field': ['get', 'name'],  // Get the device name from the properties
                                            'text-size': 12,                // Adjust the text size as needed
                                            'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
                                            'text-offset': [0, 3],        // Offset the text above the icon
                                            'text-anchor': 'top',           // Position the text above the icon
                                            'text-allow-overlap': false,    // Prevent overlapping text
                                        },
                                        paint: {
                                            'icon-halo-color': ['get', 'color'], // Set the halo color (e.g., red)
                                            'icon-halo-width': 2, // Set the width of the halo
                                            'icon-halo-blur': 1, // Optionally add some blur to the halo
                                        }
                                    })

                                    mapRef.current!.on('click', 'globalstar-layer', handleMouseClickGlobalstar(mapRef.current!, navigate))
                                }
                            })
                        })
                    })
                }
            })
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove()
            }
        }
    }, [mapEvents, mapReports, mapVesselData, navigate])

    //------------------------------------------------------------------------------------------------------------
    // Update flight markers when flight data changes and globalstar data changes
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (mapRef.current && mapRef.current.getSource('flights')) {
            const flightSource = mapRef.current.getSource('flights') as mapboxgl.GeoJSONSource
            flightSource.setData(flightGeoJson as GeoJSON.FeatureCollection)

            // Update icons based on aircraft type
            flightGeoJson.features.forEach(feature => {
                //const aircraftType = feature.properties.t; // Get the aircraft type
                const aircraftType = 'A' // TODO Check if the type is a ICAO code for a helicopter
                const iconImage = (aircraftType && aircraftType.toString().includes('H')) ? 'helicopter-icon' : 'airplane-icon' // Determine icon

                // Update the icon for each feature
                mapRef.current!.setLayoutProperty(`flight-layer`, 'icon-image', iconImage)
            })
        }


        if (mapRef.current && mapRef.current.getSource('globalstar')) {
            const globastarSource = mapRef.current.getSource('globalstar') as mapboxgl.GeoJSONSource
            globastarSource.setData(globalStarGeoJson as GeoJSON.FeatureCollection)

            // Update icons base on type
            globalStarGeoJson.features.forEach(feature => {
                const modelId = feature.properties.modelId
                let iconImage = 'gen4-icon' // default icon

                switch (modelId) {
                    case 'SPOTTRACE':
                        iconImage = 'trace-icon'
                        break
                    case 'GEN4':
                        iconImage = 'gen4-icon'
                        break
                    case 'SPOTX':
                        iconImage = 'spotx-icon'
                        break
                    default:
                        console.warn(`Unknown modelId: ${modelId}`)
                }

                // Update the icon for each feature based on the modelId
                mapRef.current!.setLayoutProperty('globalstar-layer', 'icon-image', iconImage)

            })
        }


    }, [mapFlightData, flightGeoJson, mapGlobalstarDevices])

    return <div id="map" style={{height: '100%'}} ref={mapContainerRef}/>
}


export default MonitorMap
