import React from 'react'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { VesselFilter } from './VesselFilter'
import { VesselList } from './VesselList'
import { Route, Routes } from 'react-router-dom'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { VesselDetails } from './VesselDetails'
import { IconButton } from '@mui/joy'
import { useVessel } from '../MaritimeProvider'
import { t } from 'i18next'

interface Props {

}

export function VesselContent({}: Props) {
  const { showInMap, setShowInMap } = useVessel()

  return (

    <Routes>
      <Route index element={
        <DrawerContent
          title={t('maritime')}
          backUrl='/'
          actions={
            <IconButton onClick={() => setShowInMap(!showInMap)}>
              {showInMap ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          }
        >
          <VesselFilter />
          <VesselList />
        </DrawerContent>
      } />

      <Route path=':vesselId' element={<VesselDetails />} />
    </Routes>

  )
}
