import React, { useState } from 'react'
import { ToggleButtonGroup } from '@mui/material'
import { t } from 'i18next'
import { Typography } from '@mui/joy'
import { HStack, Spacer } from '../../../../lib/components/flex'
import { VerticalAlignTopOutlined } from '@mui/icons-material'
import { SmallSwitcher } from './styled-components'

interface NavigationSwitcherProps {
  location: string
  onSwitchView: () => void
}

export function NavigationSwitcher({ location, onSwitchView }: NavigationSwitcherProps) {

  const [locationBar, setLocationBar] = useState(location)

  const handleChange = (event: React.MouseEvent<HTMLElement>, newLocation: string | null) => {
    if (newLocation) {
      setLocationBar(newLocation)
      onSwitchView()
    }
  }

  return (
    <HStack sx={{ marginBottom: '0.2rem' }}>
      <Typography sx={{ marginLeft: '0.5rem', marginRight: '0.3rem', width: '5rem' }}>{t('navigation')}</Typography>
      <Spacer/>
      <ToggleButtonGroup
        color="primary"
        value={locationBar}
        onChange={handleChange}
        exclusive
        aria-label="Language"
        sx={{ height: '25px', marginRight: '0.5rem' }}
      >
        <SmallSwitcher value="side"><VerticalAlignTopOutlined sx={{ width: '18px', height: '18px', transform: 'rotate(270deg)' }}/></SmallSwitcher>
        <SmallSwitcher value="top"><VerticalAlignTopOutlined sx={{ width: '18px', height: '18px' }}/></SmallSwitcher>
      </ToggleButtonGroup>
    </HStack>
  )
}