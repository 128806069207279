import React from 'react'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { EventFilter } from './EventFilter'
import { EventList } from './EventList'
import { Route, Routes } from 'react-router-dom'
import { EventDetails } from './EventDetails'
import { IconButton } from '@mui/material'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { useEvents } from '../EventProvider'
import { t } from 'i18next'

interface Props {

}

export function EventContent({}: Props) {
  const { showInMap, setShowInMap } = useEvents()

  return (

    <Routes>
      <Route index element={
        <DrawerContent
          title={t('events')}
          backUrl='/'
          actions={
            <IconButton onClick={() => setShowInMap(!showInMap)}>
              {showInMap ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          }
        >
          <EventFilter />
          <EventList />
        </DrawerContent>
      } />

      <Route path=':eventId' element={<EventDetails />} />
    </Routes>

  )
}
