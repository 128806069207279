import React from 'react'
import { useParams } from 'react-router-dom'
import { DrawerContent } from '../../_more/app-layout/DrawerContent'
import { isNotPresent } from '../../../../lib/utils/utils'
import { Box, Divider, Typography } from '@mui/joy'
import { format } from 'date-fns'
import { VStack } from '../../../../lib/components/flex'
import ArrowIcon from '@mui/icons-material/East'
import { useVessel } from '../MaritimeProvider'
import { HStackBoxVesselDetails, HStackShipDataVesselDetails } from '../../_more/app-layout/styled-components'
import { t } from 'i18next'


export function VesselDetails() {
  const vesselId: string = useParams().vesselId!
  const { vesselData } = useVessel()

  const isImage = true

  const vessel = vesselData.find(it => it.MMSI.toString() === vesselId)

  const formatTime = (eta: string | number | Date | null) => eta === '' || eta == null ? t('unknown') : eta ? format(new Date(eta), 'dd.MM.yyyy HH:mm') : ''

  const formatSpeedIntoKnots = (speed: any) => speed / 10

  const checkEmpty = (value: any) => isNotPresent(value) || value === -1 ? t('unknown') : value

  const checkEmptyCountry = (value: any) => isNotPresent(value) || value === -1 ? '' : value


  if (isNotPresent(vessel)) return (
    <DrawerContent title='' backUrl='/maritim'>
      {t('vesselNotFound')}
    </DrawerContent>
  )

  return (
    <DrawerContent title={vessel.CALLSIGN} backUrl='/maritim'>
      
        <VStack>

            { isImage ? (
                <Box component="img" sx={{ width: '100%', height: 'auto' }}
                alt={`Image of ${vessel.CALLSIGN}`}
                src={'https://images.marinetraffic.com/collection/534918.webp'}
                />
                ) : (<Box></Box>)

            }

            
            <Divider sx={{ '--Divider-childPosition': '50%' }}>
              {t('shipInformation')}
            </Divider>
            <HStackBoxVesselDetails>
                <HStackShipDataVesselDetails>
                    <Typography>{vessel.SHIPNAME}</Typography>
                </HStackShipDataVesselDetails>
                <HStackShipDataVesselDetails>
                    <Typography>IMO:</Typography>
                    <Typography>{checkEmpty(vessel.IMO)}</Typography>
                </HStackShipDataVesselDetails>
            </HStackBoxVesselDetails>
            <HStackBoxVesselDetails>
                <HStackShipDataVesselDetails>
                    <Typography>{t('length')}:</Typography>
                    <Typography>{checkEmpty(vessel.LENGTH)} m</Typography>
                </HStackShipDataVesselDetails>
                <HStackShipDataVesselDetails>
                    <Typography>{t('flag')}:</Typography>
                    <Typography>{checkEmpty(vessel.FLAG)}</Typography>
                </HStackShipDataVesselDetails>
            </HStackBoxVesselDetails>
            <HStackBoxVesselDetails>
                <HStackShipDataVesselDetails>
                    <Typography>{t('length')}:</Typography>
                    <Typography>{checkEmpty(vessel.WIDTH)} m</Typography>
                </HStackShipDataVesselDetails>
                <HStackShipDataVesselDetails>
                    <Typography>{t('built')}:</Typography>
                    <Typography>{checkEmpty(vessel.YEAR_BUILT)}</Typography>
                </HStackShipDataVesselDetails>
            </HStackBoxVesselDetails>
            <Divider sx={{ '--Divider-childPosition': '50%' }}>
              {t('travel')}
            </Divider>
            <HStackBoxVesselDetails>
                <VStack sx={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '0.65rem' }}>{formatTime(vessel.LAST_PORT_TIME)}</Typography>
                    <Typography sx={{ textAlign: 'center' }}>{checkEmpty(vessel.LAST_PORT)}</Typography>
                    <Typography sx={{ fontSize: '0.65rem' }}>{checkEmptyCountry(vessel.LAST_PORT_COUNTRY)}</Typography>
                </VStack>
                <VStack sx={{ width: '10%' }}>
                    <ArrowIcon/>
                </VStack>
                <VStack sx={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '0.65rem' }}>{formatTime(vessel.ETA)}</Typography>
                    <Typography sx={{ textAlign: 'center' }}>{checkEmpty(vessel.DESTINATION)}</Typography>
                    <Typography sx={{ fontSize: '0.65rem' }}>{checkEmptyCountry(vessel.NEXT_PORT_COUNTRY)}</Typography>
                </VStack>
            </HStackBoxVesselDetails>
            <Divider sx={{ marginRight: '1rem', marginLeft: '1rem', marginTop: '0.5rem' }} />
            <HStackBoxVesselDetails sx={{ marginTop: '0.5rem' }}>
                <HStackShipDataVesselDetails>
                    <Typography>{t('speed')}:</Typography>
                    <Typography>{formatSpeedIntoKnots(vessel.SPEED)} kn</Typography>
                </HStackShipDataVesselDetails>
                <HStackShipDataVesselDetails>
                    <Typography>{t('course')}:</Typography>
                    <Typography>{checkEmpty(vessel.COURSE)} °</Typography>
                </HStackShipDataVesselDetails>
            </HStackBoxVesselDetails>
        </VStack>
    </DrawerContent>
  )
}