//------------------------------------------------------------------------------------------------------------
// Need to be configured when GeoJson implementation
//------------------------------------------------------------------------------------------------------------

import mapboxgl from "mapbox-gl"
import { handleMouseClick } from "./eventHandler"


const maritimeTypeToIcon: { [type: number]: string } = {
  6: 'icon-passenger',
  7: 'icon-cargo',
  8: 'icon-tanker',
  '-1': 'icon-default'
}

export function getIconForMarineType(type: number): string {
  if (type < 10 || type > 99) {
    return maritimeTypeToIcon[-1]
  }
  const firstDigit = Math.floor(type / 10)
  return maritimeTypeToIcon[firstDigit] || maritimeTypeToIcon[-1]
}

export const addMaritimeMarkers = (map: mapboxgl.Map, vesselData: any, navigate: (path: string) => void) => {
  if (!vesselData || vesselData.length === 0) {
    return
  }

  const popup = new mapboxgl.Popup({
    closeButton: true,
    closeOnClick: true
  })

  const data = {
    type: "FeatureCollection",
    features: vesselData.map((vessel: any) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [vessel.LON, vessel.LAT]
      },
      properties: {
        callsign: vessel.CALLSIGN,
        heading: vessel.HEADING,
        shipname: vessel.SHIPNAME,
        destination: vessel.DESTINATION,
        mmsi: vessel.MMSI,
        icon: getIconForMarineType(vessel.SHIPTYPE)
      }
    }))
  }

  if (map.getSource('vessels')) {
    (map.getSource('vessels') as mapboxgl.GeoJSONSource).setData(data as GeoJSON.FeatureCollection)
  } else {
    map.addSource('vessels', {
      type: 'geojson',
      data: data as GeoJSON.FeatureCollection
    })

    map.addLayer({
      id: 'vessel-layer',
      type: 'symbol',
      source: 'vessels',
      layout: {
        'icon-image': ['get', 'icon'],
        'icon-size': 0.03,
        'icon-allow-overlap': true,
        'icon-rotate': ['get', 'heading']
      }
    })

    map.on('click', 'vessel-layer', handleMouseClick(map, popup, navigate))

  }
}
