import { createContext, useContext } from 'react'
import axios, { AxiosInstance } from 'axios'
import useLocalStorageState from 'use-local-storage-state'
import { isNotPresent, isPresent } from '../lib/utils/utils'
import { serverBaseUrl } from './base'
import { ChildrenProps } from '../lib/types'

type SecureApiContextType = {
  authHttp: AxiosInstance,
  isLoggedIn: boolean,
  requestLoginPasswordOnly: (email: string, password: string) => Promise<void>
  requestLoginWithPassword: (email: string, password: string) => Promise<any>,
  requestLoginWithMailCode: (email: string, code: string) => Promise<void>,
  logout: () => void
}

const AuthContext = createContext<SecureApiContextType>({
  authHttp: axios,
  isLoggedIn: false,
  requestLoginPasswordOnly: () => Promise.resolve(),
  requestLoginWithPassword: () => Promise.resolve(),
  requestLoginWithMailCode: () => Promise.resolve(),
  logout: () => undefined
})

// const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI3NjJhYjViMC1lMjYyLTQxM2UtYTRhZi1mYjk1YWJkOGQ4NTciLCJzY29wZSI6IlNDT1BFX1NVUEVSX0FETUlOIiwiaXNzIjoic2VsZiIsIm9yZ2FuaXphdGlvbi1pZCI6IjJmNmUxNWQ3LTk1ZWYtNDMwZS04ZTNjLTg4YjZiNWVhZGM0NyIsImV4cCI6MTc1MTYzMzc2OSwiaWF0IjoxNzIwMDk3NzY5LCJqdGkiOiI4MjIyNjE5NS02NTI5LTRmNjUtODNjMC0yM2E3MDIyOWFlODUifQ.dkSWnUdUuWs-ZqBunAApiqG1RswYb4Ub8NKJGy6kBls'

export function Auth({ children }: ChildrenProps) {

  const [authorizationToken, setAuthorizationToken] = useLocalStorageState('authorizationToken')


  function getAuthHttp() {
    if (isNotPresent(authorizationToken)) return

    return axios.create({
      headers: {
        Authorization: `Bearer ${authorizationToken}`
      }
    })
  }

  async function requestLoginPasswordOnly(email: string, password: string) {
    const response = await axios.post<String>(`${serverBaseUrl}/authentication/login`, {
      email,
      password
    })

    setAuthorizationToken(response.data)
  }

  async function requestLoginWithPassword(email: string, password: string) {
    return await axios.post(`${serverBaseUrl}/login/request-with-password`, {
      email,
      password
    })
  }

  async function requestLoginWithMailCode(email: string, code: string) {
    const response = await axios.post<String>(`${serverBaseUrl}/login/request-with-mail-code`, {
      email,
      password: code
    })

    setAuthorizationToken(response.data)
  }

  function logout() {
    setAuthorizationToken(undefined)
  }

  return (
    <AuthContext.Provider value={{
      authHttp: getAuthHttp()!!,
      isLoggedIn: isPresent(authorizationToken),
      requestLoginPasswordOnly,
      requestLoginWithPassword,
      requestLoginWithMailCode,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}


