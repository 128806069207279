import React, {
  createContext, useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAuth } from '../../../app/auth'
import { serverBaseUrl } from '../../../app/base'
import { ChildrenProps } from '../../../lib/types'
import { Flight } from './flight-radar-model'
import useCallOnce from '../../../lib/hooks/useCallOnce'
import useLocalStorageState from 'use-local-storage-state'
import { chill } from '../../../lib/utils/utils'
import sampleData from './ADSBExchange.json'

interface ProvidedData {
  flightData: Flight[]
  mapFlightData: Flight[]
  showInMap: boolean
  setShowInMap: (flag: boolean) => void
  typeFilter: string[]
  setTypeFilter: (sources: string[]) => void
}

const FlightContext = createContext<ProvidedData>({
  flightData: [],
  mapFlightData: [],
  showInMap: true,
  setShowInMap: chill,
  typeFilter: [],
  setTypeFilter: () => undefined,
})

export function FlightProvider({ children }: ChildrenProps) {
  const flightLogic = useFlightLogic()

  return (
    <FlightContext.Provider value={flightLogic}>
      {children}
    </FlightContext.Provider>
  )
}

export const useFlight = () => useContext(FlightContext)

function useFlightLogic() {
  const { authHttp } = useAuth()
  const [flightData, setFlightData] = useState<Flight[]>([])
  const [typeFilter, setTypeFilter] = useState<string[]>([])
  const [showInMap, setShowInMap] = useLocalStorageState('show-flights-in-map', { defaultValue: true })

  useEffect(() => {
    loadData().then(r => console.log(r))
  }, [])

  useCallOnce(() => {
    setInterval(() => {
      loadData().then(r => console.log(r))
    }, 2000)
  })

  async function loadData() {
    console.log('load flight traffic')
    try {
      const { data } = await authHttp.get<Flight[]>(`${serverBaseUrl}/flightradar/currentFlights`)
      setFlightData(data)
    } catch (error) {
      console.error('Failed to load flight data', error)
    }
  }

  const getMapData = useCallback(() => {
    return flightData.filter(flight =>
      (typeFilter.length === 0 || typeFilter.includes(String(Math.floor(flight.id / 10))))
    )
  }, [flightData, typeFilter])

  const mapFlightData = useMemo(
    () => (showInMap ? getMapData() : []),
    [getMapData, showInMap]
  )

  return {
    flightData,
    mapFlightData,
    showInMap,
    setShowInMap,
    typeFilter,
    setTypeFilter,
  }
}
