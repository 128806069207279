const handleMouseEnter = (map: mapboxgl.Map, popup: mapboxgl.Popup) => (e: mapboxgl.MapMouseEvent & any) => {
    map.getCanvas().style.cursor = 'pointer'

    const coordinates = (e.features![0].geometry as any).coordinates.slice()
    const description = (e.features![0].properties as any).description

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }

    popup.setLngLat(coordinates).setHTML(description).addTo(map)
}

const handleMouseClick = (map: mapboxgl.Map, popup: mapboxgl.Popup, navigate: (path: string, state?: any) => void) => (e: mapboxgl.MapMouseEvent & any) => {
    map.getCanvas().style.cursor = 'pointer'

    const coordinates = (e.features![0].geometry as any).coordinates.slice()
    const callsign = (e.features![0].properties as any).callsign
    const shipname = (e.features![0].properties as any).shipname
    const destination = (e.features![0].properties as any).destination

    const center = map.getCenter();

    const zoom = map.getZoom();
    const mapState = {center, zoom};

    navigate(`/maritim/${(e.features![0].properties as any).mmsi}`, {state: mapState})

    const vesselHTML = `
  <div>
    <img src="https://images.marinetraffic.com/collection/534918.webp" alt="Placeholder Ship" width="200"> <br>
    <strong>Callsign:</strong> ${callsign}<br>
    <strong>Ship Name:</strong> ${shipname}<br>
    <strong>Destination:</strong> ${destination}
  </div>
  `

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }

    // Uncomment for popup over marker
    //popup.setLngLat(coordinates).setHTML(vesselHTML).addTo(map)
}

const handleMouseLeave = (map: mapboxgl.Map, popup: mapboxgl.Popup) => () => {
    map.getCanvas().style.cursor = ''
    popup.remove()
}

const handleMouseClickFlight = (map: mapboxgl.Map, navigate: (path: string, state?: any) => void) => (e: mapboxgl.MapMouseEvent & any) => {
    map.getCanvas().style.cursor = 'pointer'
    // Save the current map state
    const center = map.getCenter();
    const zoom = map.getZoom();
    const mapState = {center, zoom};
    navigate(`/flight-radar/${(e.features![0].properties as any).hex}`, {state: mapState})
}

const handleMouseClickGlobalstar = (map: mapboxgl.Map, navigate: (path: string, state?: any) => void) => (e: mapboxgl.MapMouseEvent & any) => {
    map.getCanvas().style.cursor = 'pointer'
    // Save the current map state
    const center = map.getCenter();
    const zoom = map.getZoom();
    const mapState = {center, zoom};
    navigate(`/globalstar/devices/${(e.features![0].properties as any).id}`, {state: mapState})

}


export {handleMouseEnter, handleMouseClick, handleMouseLeave, handleMouseClickFlight, handleMouseClickGlobalstar}
