import React from 'react'
import { Box } from '@mui/joy'
import { ChildrenProps } from '../../../../lib/types'
import { DisplayNoneInSmallMode, useAdvancedStyles } from './advanced-styles'
import { UserInfo } from './UserInfo'
import { WrapperTopBar, ContentSheetBars } from './styled-components'
import { Spacer } from '../../../../lib/components/flex'

interface TopBarProps extends ChildrenProps {
  onSwitchView: () => void
}

export function TopBar({ children, onSwitchView }: TopBarProps) {
  const styles = useAdvancedStyles()


  return (
    <DisplayNoneInSmallMode>
      <WrapperTopBar {...styles}>
        <ContentSheetBars variant='outlined' sx={{ paddingLeft: '0.4rem' }}>
          <img src='/res/images/qntrol-logo-white.svg' alt='' height={32}/>
          <Box sx={{ width: 32 }} />
          {children}
          <Spacer />
          <UserInfo location="top" onSwitchView={onSwitchView}/>

        </ContentSheetBars>
      </WrapperTopBar>
    </DisplayNoneInSmallMode>
  )
}