import React from 'react'
import { Box, styled } from '@mui/joy'
import { Routing } from './routing/Routing'
import { Auth } from './auth'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslation from '../texts/en.json'
import deTranslation from '../texts/de.json'
import { BrowserRouter } from 'react-router-dom'
import { Theme } from './JoyTheme'

const Main = styled(Box)`
    width: 100%;
    height: 100%;
`

function getLanguage() {
  const savedLanguage = localStorage.getItem('language')
  if (savedLanguage) {
    return savedLanguage
  }
  const browserLang = navigator.language.split('-')[0]
  return ['de', 'en'].includes(browserLang) ? browserLang : 'en'
}

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      de: {
        translation: deTranslation,
      },
    },
    lng: getLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

const App = () => {
  return (
    <Theme>
      <BrowserRouter>
        <Main>
          <Auth>
            <Routing />
          </Auth>
        </Main>
      </BrowserRouter>
    </Theme>
  )
}

export default App
