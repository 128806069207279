import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MonitorPage } from '../../modules/monitor/MonitorPage'
import { useAuth } from '../auth'
import { SimpleLoginPage } from '../../modules/login/SimpleLoginPage'

interface Props {

}

export function Routing({}: Props) {

  const { isLoggedIn } = useAuth()

  return (
    <Routes>

      {/* <Route path='/*' element={<MonitorPage />}>

      </Route>*/}


      <Route path='/*' element={
        isLoggedIn
          ? <MonitorPage />
          : <Navigate to='/login' replace={true} />
      }>
      </Route>

      <Route path='login' element={<SimpleLoginPage />} />


    </Routes>
  )
}
