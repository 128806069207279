import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useAuth } from '../../../app/auth'
import { serverBaseUrl } from '../../../app/base'
import { ChildrenProps } from '../../../lib/types'
import { chill, isPresent } from '../../../lib/utils/utils'
import useCallOnce from '../../../lib/hooks/useCallOnce'
import { Report } from './report-model'

interface ProvidedData {
  reports: Report[]
  mapReports: Report[]
  saveReport: (report: Report) => void,
  showInMap: boolean,
  setShowInMap: (flag: boolean) => void,
}

const ReportContext = createContext<ProvidedData>({
  reports: [],
  mapReports: [],
  saveReport: chill,
  showInMap: true,
  setShowInMap: chill,
})

export function ReportProvider({ children }: ChildrenProps) {
  const reportLogic = useReportLogic()

  return (
    <ReportContext.Provider value={reportLogic}>
      {children}
    </ReportContext.Provider>
  )
}

export function useReports() {
  return useContext(ReportContext)
}


function useReportLogic() {
  const { authHttp } = useAuth()
  const [reports, setReports] = useState<Report[]>([])

  const [showInMap, setShowInMap] = useState(true)

  const mapReports = useMemo(
    () => showInMap ? reports : [],
    [reports, showInMap]
  )

  useEffect(() => {
    loadData()
  }, [])

  useCallOnce(() => {
    setInterval(() => {
      // loadData()
    }, 10000)
  })

  async function loadData() {
    console.log('load reports')
    const { data } = await authHttp.get<Report[]>(`${serverBaseUrl}/reports`)
    setReports(data)
  }

  async function saveReport(report: Report) {
    if (isPresent(report.id)) {
      await authHttp.put<Report[]>(`${serverBaseUrl}/reports/${report.id}`, report)
    } else {
      await authHttp.post<Report[]>(`${serverBaseUrl}/reports`, report)
    }
    loadData()
  }

  return {
    reports,
    mapReports,
    saveReport,
    showInMap,
    setShowInMap,
  }
}
